import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { navigation } from 'app/navigation/navigation';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { MaintenanceMessage } from './config/models/maintenance-message.model';
import { MaintenanceMessageService } from './config/services/maintenance-message.service';
import { TenantDataService } from './config/services/tenant-data.service';
import { SnackBarTime } from './projects/core/src/lib/constants/snack-bar';
import { FuseNavigationService } from './projects/fuse/src/lib/components/navigation/navigation.service';
import { FuseSidebarService } from './projects/fuse/src/lib/components/sidebar/sidebar.service';
import { FuseConfigService } from './projects/fuse/src/lib/services/config.service';
import { FEED_SERVICE, IFeedService } from './projects/fuse/src/lib/services/feed-service.token';
import { FuseSplashScreenService } from './projects/fuse/src/lib/services/splash-screen.service';
import { ILanguageService } from './projects/shared/src/lib/interfaces/language.interface';
import { MAXBRAIN_LANGUAGE_SERVICE } from './projects/shared/src/lib/services/language.token';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;

    isAndroid = false;
    isIOS = false;
    isKmpAppActive = false;

    smartBannerHref: string;
    smartBannerSrc: string;
    smartBannerTitle: string;

    maintenanceMessage: MaintenanceMessage;
    isMaintenanceMessageHidden = true;

    // Private
    private _unsubscribeAll: Subject<any>;

    private _showMaintenanceMessage(): void {
        this.isMaintenanceMessageHidden = false;
        this.document.body.classList.add('maintenance-on');
    }

    private _hideMaintenanceMessage(): void {
        this.isMaintenanceMessageHidden = true;
        this.document.body.classList.remove('maintenance-on');
    }

    private _fetchFeedUnreadCount(): void {
        this._feedService.getNotificationUnreadCount().pipe(takeUntil(this._unsubscribeAll)).subscribe();
    }

    /**
     * Constructor
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private _router: Router,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private _matSnackBar: MatSnackBar,
        private _deviceService: DeviceDetectorService,
        private _tenantData: TenantDataService,
        private _maintenanceMessageService: MaintenanceMessageService,
        @Inject(FEED_SERVICE) private _feedService: IFeedService,
        @Inject(MAXBRAIN_LANGUAGE_SERVICE) private languageService: ILanguageService
    ) {
        this.matIconRegistry.addSvgIcon('responsible', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_responsible_small.svg'));
        this.matIconRegistry.addSvgIcon('quiz', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_quiz_small.svg'));
        this.matIconRegistry.addSvgIcon('bellow_pass', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_bellow_pass_24px.svg'));
        this.matIconRegistry.addSvgIcon('certificate_settings', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_certificate_settings_24px.svg'));
        this.matIconRegistry.addSvgIcon('highest_grade', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_highest_grade_24px.svg'));
        this.matIconRegistry.addSvgIcon('lowest_grade', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_lowest_grade_24px.svg'));
        this.matIconRegistry.addSvgIcon('success', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_success_24px.svg'));
        this.matIconRegistry.addSvgIcon('manage_tabs', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_manage_tabs_24px.svg'));
        this.matIconRegistry.addSvgIcon('score', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_completed_status_24px.svg'));
        this.matIconRegistry.addSvgIcon('completed', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_completed_status_28px.svg'));
        this.matIconRegistry.addSvgIcon('time', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_time_24px.svg'));
        this.matIconRegistry.addSvgIcon('folder_zip', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_folder_zip.svg'));
        this.matIconRegistry.addSvgIcon('zip', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_zip.svg'));
        this.matIconRegistry.addSvgIcon('apple', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/apple.svg'));
        this.matIconRegistry.addSvgIcon('groups', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_shared_folder.svg'));
        this.matIconRegistry.addSvgIcon('status_error', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/status_error.svg'));
        this.matIconRegistry.addSvgIcon('status_in_progress', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/status_in_progress.svg'));
        this.matIconRegistry.addSvgIcon('status_canceled', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/status_canceled.svg'));
        this.matIconRegistry.addSvgIcon('status_timed_out', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/status_timed_out.svg'));
        this.matIconRegistry.addSvgIcon('status_succeeded', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/status_succeeded.svg'));
        this.matIconRegistry.addSvgIcon('status_sum', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/status_sum.svg'));
        this.matIconRegistry.addSvgIcon('certificate', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_certificate.svg'));
        this.matIconRegistry.addSvgIcon('certificate_template', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_certificate_template.svg'));
        this.matIconRegistry.addSvgIcon('download_folder', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_download_folder.svg'));
        this.matIconRegistry.addSvgIcon('new_file', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_new_file.svg'));
        this.matIconRegistry.addSvgIcon('new_folder', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_new_folder.svg'));
        this.matIconRegistry.addSvgIcon('member_area', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/member_area.svg'));
        this.matIconRegistry.addSvgIcon('unpinned', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_unpinned.svg'));
        this.matIconRegistry.addSvgIcon('student_male', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/student_male.svg'));
        this.matIconRegistry.addSvgIcon('sections_completed', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/sections_completed.svg'));
        this.matIconRegistry.addSvgIcon('sections_completed_black', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/sections_completed_black.svg'));
        this.matIconRegistry.addSvgIcon('unpinned_hovered', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_unpinned_hovered.svg'));
        this.matIconRegistry.addSvgIcon('course_offering_template', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/course_offering_template.svg'));
        this.matIconRegistry.addSvgIcon('assign_user_to_audience', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/assign_user_to_audience.svg'));
        this.matIconRegistry.addSvgIcon('assign_user_to_experience', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/assign_user_to_experience.svg'));
        this.matIconRegistry.addSvgIcon('edit_message', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/edit_message.svg'));
        this.matIconRegistry.addSvgIcon('pinned', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_pinned.svg'));
        this.matIconRegistry.addSvgIcon('survey', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/survey.svg'));
        this.matIconRegistry.addSvgIcon('survey_group', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/survey_group.svg'));
        this.matIconRegistry.addSvgIcon('new_tab', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/new_tab.svg'));
        this.matIconRegistry.addSvgIcon('group_of_people', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/group_of_people.svg'));
        this.matIconRegistry.addSvgIcon('text_section', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/text_section_ic.svg'));
        this.matIconRegistry.addSvgIcon('folder_course_content', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_folder_course_content.svg'));
        this.matIconRegistry.addSvgIcon('statistic_document', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_statistic_document.svg'));
        this.matIconRegistry.addSvgIcon('empty', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_empty.svg'));
        this.matIconRegistry.addSvgIcon('synchronize', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/synchronize.svg'));
        this.matIconRegistry.addSvgIcon('schedule', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/schedule.svg'));
        this.matIconRegistry.addSvgIcon('schedule_error', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/schedule_error.svg'));
        this.matIconRegistry.addSvgIcon('services', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_services.svg'));
        this.matIconRegistry.addSvgIcon('mandatory', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/mandatory.svg'));
        this.matIconRegistry.addSvgIcon('knowledge_sharing', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/knowledge_sharing.svg'));
        this.matIconRegistry.addSvgIcon('opened_folder', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_opened_folder.svg'));
        this.matIconRegistry.addSvgIcon('professor', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_professor.svg'));
        this.matIconRegistry.addSvgIcon('add_subcategory', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/add_subcategory.svg'));
        this.matIconRegistry.addSvgIcon('zoom_meeting', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/zoom_meeting.svg'));
        this.matIconRegistry.addSvgIcon('zoom_webinar', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/zoom_webinar.svg'));
        this.matIconRegistry.addSvgIcon('filter', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/filter.svg'));
        this.matIconRegistry.addSvgIcon('excel', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/excel.svg'));
        this.matIconRegistry.addSvgIcon('schedule_tab', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/schedule_tab.svg'));
        this.matIconRegistry.addSvgIcon('participants', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/participants.svg'));
        this.matIconRegistry.addSvgIcon('collaboration', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/collaboration.svg'));
        this.matIconRegistry.addSvgIcon('upload', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_upload.svg'));
        this.matIconRegistry.addSvgIcon('download', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_download.svg'));
        this.matIconRegistry.addSvgIcon('notify', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/notify.svg'));
        this.matIconRegistry.addSvgIcon('add_new', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/add_new.svg'));
        this.matIconRegistry.addSvgIcon('chat_bubble', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/chat_bubble.svg'));
        this.matIconRegistry.addSvgIcon('privacy_policy', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_privacy_policy.svg'));
        this.matIconRegistry.addSvgIcon('ask_question', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_ask_question.svg'));
        this.matIconRegistry.addSvgIcon('key', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_key.svg'));
        this.matIconRegistry.addSvgIcon('document', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/document.svg'));
        this.matIconRegistry.addSvgIcon('my_documents', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_my_documents.svg'));
        this.matIconRegistry.addSvgIcon('lock', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_lock.svg'));
        this.matIconRegistry.addSvgIcon('conference', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/conference.svg'));
        this.matIconRegistry.addSvgIcon('not_checked_circle', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_not_checked_circle.svg'));
        this.matIconRegistry.addSvgIcon('task_completed', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_task_completed.svg'));
        this.matIconRegistry.addSvgIcon(
            'stacked_organizational_chart_highlighted_first_node',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_stacked_organizational_chart_highlighted_first_node.svg')
        );
        this.matIconRegistry.addSvgIcon('dashboard', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_dashboard.svg'));
        this.matIconRegistry.addSvgIcon('managment', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_managment.svg'));
        this.matIconRegistry.addSvgIcon('sections_and_content', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_sections_and_content.svg'));
        this.matIconRegistry.addSvgIcon('content_only', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_content_only.svg'));
        this.matIconRegistry.addSvgIcon('loading_bar', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_loading_bar.svg'));
        this.matIconRegistry.addSvgIcon('search_illustration', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_search_illustration.svg'));
        this.matIconRegistry.addSvgIcon('members_illustration', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_members_illustration.svg'));
        this.matIconRegistry.addSvgIcon('dashboard_up', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_dashboard_up.svg'));
        this.matIconRegistry.addSvgIcon('dashboard_down', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_dashboard_down.svg'));
        this.matIconRegistry.addSvgIcon('dashboard_tot_avg_learning_time', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_tot_avg_learning_time.svg'));
        this.matIconRegistry.addSvgIcon(
            'dashboard_grow_daily_page_views',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_dashboard_grow_daily_page_views.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'dashboard_grow_daily_posting_link_followed',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_dashboard_grow_daily_posting_link_followed.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'dashboard_learner_login_frequency',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_dashboard_learner_login_frequency.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'dashboard_learners_by_device_type',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_dashboard_learners_by_device_type.svg')
        );
        this.matIconRegistry.addSvgIcon('dashboard_learners_by_gender', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_dashboard_learners_by_gender.svg'));
        this.matIconRegistry.addSvgIcon(
            'dashboard_top_courses_by_active_learners',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_dashboard_top_courses_by_active_learners.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'dashboard_daily_active_learners',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_dashboard_daily_active_learners.svg')
        );
        this.matIconRegistry.addSvgIcon('dashboard_avg_learning_time', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_dashboard_avg_learning_time.svg'));
        this.matIconRegistry.addSvgIcon('tear_off_calendar', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/tear_off_calendar.svg'));
        this.matIconRegistry.addSvgIcon('report_card', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/report_card.svg'));
        this.matIconRegistry.addSvgIcon('microsoft_excel', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_microsoft_excel.svg'));
        this.matIconRegistry.addSvgIcon('menu', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_menu.svg'));
        this.matIconRegistry.addSvgIcon('search_results', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_search_results.svg'));
        this.matIconRegistry.addSvgIcon('table_columns_dropdown', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_table_columns_dropdown.svg'));
        this.matIconRegistry.addSvgIcon('lock_sharp', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_lock_sharp.svg'));
        this.matIconRegistry.addSvgIcon('mdi_people', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_mdi_people.svg'));
        this.matIconRegistry.addSvgIcon('edit_possible', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_edit_possible.svg'));
        this.matIconRegistry.addSvgIcon('edit_not_possible', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_edit_not_possible.svg'));
        this.matIconRegistry.addSvgIcon('keyword_tag', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_keyword_tag.svg'));
        this.matIconRegistry.addSvgIcon('search_results', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_search_results.svg'));
        this.matIconRegistry.addSvgIcon('paper_add', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_paper_add.svg'));
        this.matIconRegistry.addSvgIcon('team', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_team.svg'));
        this.matIconRegistry.addSvgIcon('course_completion_state', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_course_completion_state.svg'));
        this.matIconRegistry.addSvgIcon('filter_by', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_filter.svg'));
        this.matIconRegistry.addSvgIcon('checkmark_filled', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_checkmark_filled.svg'));
        this.matIconRegistry.addSvgIcon('qr_code', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_qr_code.svg'));
        this.matIconRegistry.addSvgIcon('lock_reset', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_lock_reset.svg'));
        this.matIconRegistry.addSvgIcon('search_no_results', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_search_no_results.svg'));
        this.matIconRegistry.addSvgIcon('paper_add', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_paper_add.svg'));
        this.matIconRegistry.addSvgIcon('copy', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_copy.svg'));
        this.matIconRegistry.addSvgIcon('pdf', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_open_pdf.svg'));
        this.matIconRegistry.addSvgIcon('send', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_send.svg'));
        this.matIconRegistry.addSvgIcon('edit', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_edit.svg'));
        this.matIconRegistry.addSvgIcon('delete', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_delete.svg'));
        this.matIconRegistry.addSvgIcon('add_certificate', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_add_certificate.svg'));
        this.matIconRegistry.addSvgIcon('remove_certificate', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_remove_certificate.svg'));
        this.matIconRegistry.addSvgIcon('school', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_school.svg'));
        this.matIconRegistry.addSvgIcon('clock_in-progress', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_clock_in-progress.svg'));
        this.matIconRegistry.addSvgIcon('tick_completed', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_tick_completed.svg'));
        this.matIconRegistry.addSvgIcon('account_cancel', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_account_cancel.svg'));
        this.matIconRegistry.addSvgIcon('question_library', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_question_library.svg'));
        this.matIconRegistry.addSvgIcon('carbon_white_paper', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_carbon_white_paper.svg'));
        this.matIconRegistry.addSvgIcon('school_outline', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_school_outline.svg'));
        this.matIconRegistry.addSvgIcon('dashboard_icon', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_dashborad_icon.svg'));
        this.matIconRegistry.addSvgIcon('free_text', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_free_text.svg'));
        this.matIconRegistry.addSvgIcon('multiple_choice', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_multiple_choice.svg'));
        this.matIconRegistry.addSvgIcon('sorter', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_sorter.svg'));
        this.matIconRegistry.addSvgIcon('true_or_false', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_true_or_false.svg'));
        this.matIconRegistry.addSvgIcon('checkboxes', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_checkboxes.svg'));
        this.matIconRegistry.addSvgIcon('categorizer', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_categorizer.svg'));
        this.matIconRegistry.addSvgIcon('categorizer_checkboxes', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_categorizer_checkboxes.svg'));
        this.matIconRegistry.addSvgIcon('highlight_text', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_highlight_text.svg'));
        this.matIconRegistry.addSvgIcon('hot_spot', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_hot_spot.svg'));
        this.matIconRegistry.addSvgIcon('fill_in_gap', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_fill_in_gap.svg'));
        this.matIconRegistry.addSvgIcon('folder', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_folder.svg'));
        this.matIconRegistry.addSvgIcon('calendar', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_calendar.svg'));
        this.matIconRegistry.addSvgIcon('events_illustration', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_events_illustration.svg'));
        this.matIconRegistry.addSvgIcon('grades_illustration', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_grades_illustration.svg'));
        this.matIconRegistry.addSvgIcon('bg_illustration', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/bg-illustration.svg'));
        this.matIconRegistry.addSvgIcon('logos_zoom', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/logos_zoom.svg'));
        this.matIconRegistry.addSvgIcon('course', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_course.svg'));
        this.matIconRegistry.addSvgIcon('course_private', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_course_private.svg'));
        this.matIconRegistry.addSvgIcon('e_learning', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_e_learning.svg'));
        this.matIconRegistry.addSvgIcon('program', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_program.svg'));
        this.matIconRegistry.addSvgIcon('course_current_color', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_course_current_color.svg'));
        this.matIconRegistry.addSvgIcon('course_private_current_color', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_course_private_current_color.svg'));
        this.matIconRegistry.addSvgIcon('e_learning_current_color', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_e_learning_current_color.svg'));
        this.matIconRegistry.addSvgIcon('program_current_color', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_program_current_color.svg'));
        this.matIconRegistry.addSvgIcon('e_learning_section', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_e_learning_section.svg'));
        this.matIconRegistry.addSvgIcon('leading_icon', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/leading_icon.svg'));
        this.matIconRegistry.addSvgIcon('newspaper', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_newspaper.svg'));
        this.matIconRegistry.addSvgIcon('urgent', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_urgent.svg'));
        this.matIconRegistry.addSvgIcon('feed_illustration', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_feed_illustration.svg'));
        this.matIconRegistry.addSvgIcon('clarity_language', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_clarity_language.svg'));
        this.matIconRegistry.addSvgIcon('unpinned_module', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_unpinned_module.svg'));
        this.matIconRegistry.addSvgIcon('pinned_module', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_pinned_module.svg'));
        this.matIconRegistry.addSvgIcon('team_progress_illustration', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_team_progress_illustration.svg'));
        this.matIconRegistry.addSvgIcon('right_arrow', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_right_arrow.svg'));
        this.matIconRegistry.addSvgIcon('nussbaum_menu', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_nussbaum_menu.svg'));
        this.matIconRegistry.addSvgIcon('nussbaum_konfigurator', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_nussbaum_konfigurator.svg'));
        this.matIconRegistry.addSvgIcon('nussbaum_shop', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_nussbaum_shop.svg'));
        this.matIconRegistry.addSvgIcon('nussbaum_prufdatenbank', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_nussbaum_prufdatenbank.svg'));
        this.matIconRegistry.addSvgIcon('checkmark_illustration', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_checkmark_illustration.svg'));
        this.matIconRegistry.addSvgIcon('send_message', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_send_message.svg'));
        this.matIconRegistry.addSvgIcon(
            'program_structure_illustration',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_program_structure_illustration.svg')
        );
        this.matIconRegistry.addSvgIcon('file_exchange', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_file_exchange.svg'));
        this.matIconRegistry.addSvgIcon('manager', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_manager.svg'));
        this.matIconRegistry.addSvgIcon('responsible', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_responsible.svg'));
        this.matIconRegistry.addSvgIcon('list', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_list.svg'));
        this.matIconRegistry.addSvgIcon('team_illustration', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ic_team_illustration.svg'));

        // Get default navigation
        this.navigation = navigation;

        // Register the navigation to the service
        this._fuseNavigationService.register(
            'main',
            navigation.filter((navItem) => navItem.default)
        );

        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation('main');

        // Add languages
        this._translateService.addLangs(languageService.languages.map((language) => language.id));

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    async ngOnInit(): Promise<void> {
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                setTimeout(() => {
                    this._fetchFeedUnreadCount();
                }, 1500);
            });

        const mobileAppValue = localStorage.getItem('kmpMobileApp');

        if (mobileAppValue === 'ios' || mobileAppValue === 'android') {
            this.isKmpAppActive = true;
        }

        this._tenantData.data$
            .pipe(
                filter((data) => !!data),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe((data) => {
                this.isIOS =
                    /iPhone|iPad|iPod/i.test(navigator.userAgent) ||
                    /iPhone|iPad|iPod/i.test(navigator.platform) ||
                    /iPhone|iPad|iPod/i.test(this._deviceService.device) ||
                    this._deviceService.os.toLowerCase() === 'ios';
                this.isAndroid =
                    /Android/i.test(navigator.userAgent) ||
                    /Android/i.test(navigator.platform) ||
                    /Android/i.test(this._deviceService.device) ||
                    this._deviceService.os.toLowerCase() === 'android';

                if (this.isIOS || this.isAndroid) {
                    this.smartBannerSrc = data.mobileLogoSrc;
                    this.smartBannerTitle = data.mobileTitle;

                    if (this.isIOS) {
                        this.smartBannerHref = `itms-apps://itunes.apple.com/app/apple-store/id${data.ios}?mt=8`;
                    }

                    if (this.isAndroid) {
                        this.smartBannerHref = `https://play.google.com/store/apps/details?id=${data.android}`;
                    }
                } else if (this._deviceService.isTablet()) {
                    // TODO: what then?
                }
            });

        // Subscribe to config changes
        this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
            this.fuseConfig = config;

            // Boxed
            if (this.fuseConfig.layout.width === 'boxed') {
                this.document.body.classList.add('boxed');
            } else {
                this.document.body.classList.remove('boxed');
            }

            // Color theme - Use normal for loop for IE11 compatibility
            for (let i = 0; i < this.document.body.classList.length; i++) {
                const className = this.document.body.classList[i];

                if (className.startsWith('theme-')) {
                    this.document.body.classList.remove(className);
                }
            }

            this.document.body.classList.add(this.fuseConfig.colorTheme);

            this.detectIE();
        });

        this.maintenanceMessage = await this._maintenanceMessageService.fetchMaintenanceMessage();

        const defaultLanguage = this.languageService.getDefaultLanguage();

        // Set HTML Language header
        this.document.documentElement.lang = defaultLanguage.id;

        this._translateService.onLangChange.subscribe((params: LangChangeEvent) => {
            this.document.documentElement.lang = params.lang;

            if (
                this.maintenanceMessage.id &&
                (!localStorage.getItem('maintenanceMessageId') || localStorage.getItem('maintenanceMessageId') !== this.maintenanceMessage.messageId)
            ) {
                switch (params.lang) {
                    case 'en-GB':
                        if (this.maintenanceMessage.message.en) {
                            this.maintenanceMessage = Object.assign({}, this.maintenanceMessage, {
                                messageToShow: this.maintenanceMessage.message.en,
                            });
                            this._showMaintenanceMessage();
                        } else {
                            this._hideMaintenanceMessage();
                        }
                        break;
                    case 'de-DE':
                        if (this.maintenanceMessage.message.de) {
                            this.maintenanceMessage = Object.assign({}, this.maintenanceMessage, {
                                messageToShow: this.maintenanceMessage.message.de,
                            });
                            this._showMaintenanceMessage();
                        } else {
                            this._hideMaintenanceMessage();
                        }
                        break;
                    case 'fr-FR':
                        if (this.maintenanceMessage.message.fr) {
                            this.maintenanceMessage = Object.assign({}, this.maintenanceMessage, {
                                messageToShow: this.maintenanceMessage.message.fr,
                            });
                            this._showMaintenanceMessage();
                        } else {
                            this._hideMaintenanceMessage();
                        }
                        break;
                    case 'it-CH':
                        if (this.maintenanceMessage.message.it) {
                            this.maintenanceMessage = Object.assign({}, this.maintenanceMessage, {
                                messageToShow: this.maintenanceMessage.message.it,
                            });
                            this.isMaintenanceMessageHidden = false;
                        } else {
                            this.isMaintenanceMessageHidden = true;
                        }
                        break;
                    default:
                        if (this.maintenanceMessage.message.en) {
                            this.maintenanceMessage = Object.assign({}, this.maintenanceMessage, {
                                messageToShow: this.maintenanceMessage.message.en,
                            });
                            this._showMaintenanceMessage();
                        } else {
                            this._hideMaintenanceMessage();
                        }
                        break;
                }
            }
        });

        // Set the default language
        // this language will be used as a fallback when a translation isn't found in the current language
        this._translateService.setDefaultLang(defaultLanguage.id);
        // the lang to use, if the lang isn't available, it will use the current loader to get them
        this._translateService.use(defaultLanguage.id);
    }

    closeMaintenanceMessage(): void {
        localStorage.setItem('maintenanceMessageId', this.maintenanceMessage.messageId);
        this._hideMaintenanceMessage();
    }

    detectIE(): void {
        const ua = window.navigator.userAgent;
        const trident = ua.indexOf('Trident/');

        const msie = ua.indexOf('MSIE');
        // IE 11 or older => return version number
        if (msie > 0 || trident > 0) {
            this._matSnackBar.open('To get the best experience of MaxBrain Classroom, please use Chrome, Firefox, Safari or Edge.', 'OK', {
                verticalPosition: 'top',
                duration: SnackBarTime.medium,
            });
            return;
        }

        return;
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }
}

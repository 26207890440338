import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Store } from '@ngxs/store';
import { IEnvironment } from 'app/projects/core/src/lib/interfaces/environment.interface';
import { MAXBRAIN_ENVIRONMENT } from 'app/projects/core/src/lib/services/environment.token';
import { FetchAccessTokenFromRefreshTokenFailureAction } from '../actions/fetch-access-token-from-refresh-token.failure.action';
import { SetRedirectUrl } from '../actions/set-redirect-url.action';
import { SetReponseLoginApp } from '../actions/set-reponse-login-app.action';
import { SetSession } from '../actions/set-session.action';
import { IReponseApiLogin } from '../interfaces/reponse-api-login.interface';
import { MaxBrainAuthState } from '../models/auth.state';
import { LoginAppApiService } from './login-app.api-service';

/**
 * Service use to manage the Login App JWT
 * We will use the local storage to manage the access
 * because it took too long to access the state
 */
@Injectable()
export class LoginAppService {
    private env: IEnvironment;

    private idTimeOutRefreshToken = null;

    constructor(
        @Inject(MAXBRAIN_ENVIRONMENT) environment: IEnvironment,
        private _action$: Actions,
        private _store: Store,
        private router: Router,
        private refreshTokenApiService: LoginAppApiService
    ) {
        this.env = environment;
    }

    get refreshToken(): string {
        return localStorage.getItem('refreshToken');
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken');
    }

    get expireAt(): number {
        return Number.parseInt(localStorage.getItem('expiresAt'), 10);
    }

    /**
     * Check if we have a refresh token available in the state or the localestorage
     */
    hasValidRefreshToken(): boolean {
        const refreshToken = this._store.selectSnapshot(MaxBrainAuthState.getRefreshToken) || localStorage.getItem('refreshToken');

        return !!refreshToken;
    }

    /**
     * We check if there is a valid "refresh" token in the state
     * if not we check if the information is available in the localStorage of the navigator
     * and we check that the expiration date is still valid
     */
    hasValidAccessToken(): boolean {
        const accessTokenLocal = localStorage.getItem('accessToken');
        /**
         * if the token from local is invalid and we have token in the state
         * we reset also the token in the state
         */
        if (!accessTokenLocal) {
            return false;
        }

        const expireTokenLocal = Number.parseInt(localStorage.getItem('expiresAt'), 10) || null;
        const currentTime = new Date().getTime() / 1000;

        return !(!expireTokenLocal || expireTokenLocal < currentTime);
    }

    redirectToLoginPage(): void {
        // save the url to redirect
        this._store.dispatch(new SetRedirectUrl(this.router.url));

        // redirect to login APP
        // check if local instance (login url is not the same as classroom url)
        if (window.location.host.includes('edu.local')) {
            window.location.href = `https://${localStorage.getItem('subdomain')}.${this.env.baseUrlSecondPart}/login`;
        } else {
            window.location.href = `${window.location.origin}/login`;
        }
    }

    /**
     * We setup a callback to refresh the accessToken before expiration
     * @param timeOut
     * @param refreshTokenCallback
     */
    scheduleTokenCallBack(): void {
        const currentTimestamp = Math.round(new Date().getTime() / 1000);
        const tokenTimestamp = Number.parseInt(localStorage.getItem('expiresAt'), 10);
        const timeBeforeDeadline = 60; // refresh 1 minute before the deadline - 60 secondes
        let timeSetTimeout = tokenTimestamp - currentTimestamp - timeBeforeDeadline;

        timeSetTimeout = timeSetTimeout < 0 ? 1 : timeSetTimeout;

        const refreshTokenCallback = localStorage.getItem('refreshToken');

        if (timeSetTimeout && refreshTokenCallback) {
            if (this.idTimeOutRefreshToken) {
                clearTimeout(this.idTimeOutRefreshToken);
            }

            // schedule replay when the expirateAt arrive
            this.idTimeOutRefreshToken = setTimeout(
                async (refreshTokenCallBack) => {
                    await this.fetchAccesToken(refreshTokenCallBack);
                },
                timeSetTimeout * 1000,
                refreshTokenCallback
            );
        }
    }

    /**
     *
     * @param refreshToken
     */
    async fetchAccesToken(refreshToken?): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            if (!refreshToken) {
                refreshToken = this.refreshToken;
            }

            // no refresh token
            if (!refreshToken) {
                return resolve(false);
            }

            // we update the refreshToken in localstorage and State
            this.refreshTokenApiService
                .fetchTokenAccessToken(refreshToken)
                .then((payload: IReponseApiLogin) => {
                    // update local storage
                    localStorage.setItem('expiresAt', payload.token_expiration);
                    localStorage.setItem('accessToken', payload.token);
                    localStorage.setItem('refreshToken', payload.refresh_token);

                    // save the url to redirect
                    this._store.dispatch(new SetRedirectUrl(this.router.url));

                    // update state
                    this._store.dispatch(new SetReponseLoginApp(payload));

                    // schedule replay when the expirateAt arrive
                    this.scheduleTokenCallBack();

                    // create the session with the access token
                    resolve(this.connectWithAccessToken(payload.token, payload.token_expiration));
                })
                .catch((reason) => {
                    this._store.dispatch(new FetchAccessTokenFromRefreshTokenFailureAction(reason));
                    console.error(reason);
                    return resolve(false);
                });
        });
    }

    /**
     * We will wait for SetSession success or fail outside
     * @param accessToken
     * @param expiresIn
     */
    public connectWithAccessToken(accessToken, expiresIn): Promise<boolean> {
        /**
         * We check that the idTimeOutRefreshToken is not null
         * if null mean we need to schedule refresh access token
         */

        if (!this.idTimeOutRefreshToken) {
            this.scheduleTokenCallBack();
        }
        const userProfile = JSON.parse(localStorage.getItem('userProfile'));
        return this._store
            .dispatch(
                new SetSession({
                    authResult: {
                        accessToken: accessToken,
                        expiresIn: expiresIn,
                    },
                    profile: userProfile,
                })
            )
            .toPromise()
            .then(() => true)
            .catch(() => false);
    }

    /**
     * remove the login info from the locale storage
     * we do the same in the state but it is slower
     */
    public clearLocalStorage(): void {
        localStorage.removeItem('expiresAt');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
    }
}

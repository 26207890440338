import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Actions, Select, Store, ofActionDispatched } from '@ngxs/store';
import { FetchTeamsUsers, FetchTeamsUsersSuccess } from 'app/library/team/actions';
import { User } from 'app/projects/user/src/lib/models/user';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import {
    FetchAllUsers,
    FetchAllUsersSuccess,
    FetchAvailableChatUsers,
    FetchAvailableChatUsersSuccess,
    FetchChatUsers,
    FetchChatUsersSuccess,
    FetchCourseOfferingUsers,
    FetchCourseOfferingUsersSuccess,
    FetchParticipantsAndResponsibles,
    FetchParticipantsAndResponsiblesSuccess,
} from '../../actions';
import { FetchAvailableUsers, FetchAvailableUsersSuccess } from '../../actions/index';
import { ExtendedAttributeFilterInterface } from '../../interfaces/extended-attribute-filer.interface';
import { AllUsersState } from '../../models/all-users.state';

export class AllUserDataSource implements DataSource<User> {
    @Select(AllUsersState.getFiltersList)
    filter$: Observable<User>;

    private _loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this._loadingSubject.asObservable();

    constructor(private _filteredUser$: Observable<User[]>, private _store: Store, private _action$: Actions) {}

    connect(collectionViewer: CollectionViewer): Observable<User[]> {
        return this._filteredUser$;
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this._loadingSubject.complete();
    }

    loadUsers(
        dataType: string,
        moduleIds?: string[],
        entityId?: string,
        search?: string,
        sortBy?: string,
        sortOrder?: string,
        pageSize?: number,
        pageNumber?: number,
        isUnassignedSelected?: boolean,
        role?: string,
        includeAnalytics?: boolean,
        moduleId?: string,
        experienceIds?: string[],
        audienceIds?: string[],
        teamIds?: string[],
        extendedAttributesFilters?: ExtendedAttributeFilterInterface[],
        isUnassignedSelectedAudiences?: boolean,
        isUnassignedSelectedExperiences?: boolean,
        isUnassignedSelectedTeams?: boolean,
        columns?: string[],
        userRole?: string[]
    ): void {
        this._loadingSubject.next(true);

        switch (dataType) {
            case 'users':
                this._store.dispatch(
                    new FetchAllUsers({
                        moduleIds,
                        entityId,
                        search,
                        sortBy,
                        sortOrder,
                        pageSize,
                        pageNumber: pageNumber,
                        isUnassignedSelected,
                        role,
                        includeAnalytics,
                        experienceIds,
                        audienceIds,
                        teamIds,
                        extendedAttributesFilters,
                        isUnassignedSelectedExperiences,
                        isUnassignedSelectedAudiences,
                        isUnassignedSelectedTeams,
                        columns,
                        userRole,
                    })
                );
                break;
            case 'availableUsers':
                this._store.dispatch(
                    new FetchAvailableUsers({
                        groupId: entityId,
                        search,
                        sortBy,
                        sortOrder,
                        pageSize,
                        pageNumber: pageNumber,
                    })
                );
                break;
            case 'participantsAndResponsibles':
                this._store.dispatch(
                    new FetchParticipantsAndResponsibles({
                        moduleId,
                        groupId: entityId,
                        search,
                        sortBy,
                        sortOrder,
                        pageSize,
                        pageNumber: pageNumber,
                    })
                );
                break;
            case 'chatUsers':
                this._store.dispatch(
                    new FetchAvailableChatUsers({
                        groupId: entityId,
                        search,
                        sortBy,
                        sortOrder,
                        pageSize,
                        pageNumber: pageNumber,
                    })
                );
                break;
            case 'chatAdmins':
                this._store.dispatch(
                    new FetchChatUsers({
                        moduleIds,
                        entityId,
                        search,
                        sortBy,
                        sortOrder,
                        pageSize,
                        pageNumber: pageNumber,
                        isUnassignedSelected,
                        role,
                        includeAnalytics,
                        experienceIds,
                        audienceIds,
                        teamIds,
                        extendedAttributesFilters,
                        isUnassignedSelectedExperiences,
                        isUnassignedSelectedAudiences,
                        isUnassignedSelectedTeams,
                        columns,
                        userRole,
                    })
                );
                break;
            case 'teamsUsers':
                this._store.dispatch(
                    new FetchTeamsUsers({
                        teamId: entityId,
                        search,
                        pageSize,
                        pageNumber: pageNumber,
                        role,
                    })
                );
                break;
            case 'courseOfferingUsers':
                this._store.dispatch(
                    new FetchCourseOfferingUsers({
                        entityId,
                        search,
                        sortBy,
                        sortOrder,
                        pageSize,
                        pageNumber: pageNumber,
                    })
                );
                break;
        }

        this._action$
            .pipe(
                ofActionDispatched(
                    FetchAllUsersSuccess,
                    FetchAvailableUsersSuccess,
                    FetchParticipantsAndResponsiblesSuccess,
                    FetchChatUsersSuccess,
                    FetchAvailableChatUsersSuccess,
                    FetchTeamsUsersSuccess,
                    FetchCourseOfferingUsersSuccess
                ),
                finalize(() => this._loadingSubject.next(false)),
                take(1)
            )
            .subscribe();
    }
}

<div *ngIf="!creating; else spinner">
    <app-dialog-header [title]="'MODULE.NEW'" (onClick)="dialogRef.close(null)"></app-dialog-header>

    <div
        mat-dialog-content
        fxLayout="column"
        fxLayoutAlign="start"
        [class.allow-tags-overflow]="stepper?.selectedIndex === 0 && isStepOneActive"
        *ngIf="route.children[1].children[0].params | async; let params"
        [ngSwitch]="params.type"
    >
        <div id="choose-module-type-container" fxLayout="row wrap" fxLayoutAlign="start" [@animateStagger]="{ value: '50' }" *ngSwitchCase="'choose'">
            <div class="card mb-24" fxFlex="1 1 50%">
                <a [routerLink]="['', { outlets: { modal: ['new-module', 'basic'] } }]">
                    <div class="white h-100-p p-16">
                        <mat-icon svgIcon="course"></mat-icon>
                        <span class="card-title">{{ 'NAV.NEW.MODULE.TITLE' | translate }}</span>
                        <span class="card-description">{{ 'NAV.NEW.MODULE.DESCRIPTION' | translate }}</span>
                    </div>
                </a>
            </div>
            <div class="card mb-24" fxFlex="1 1 50%" *ngIf="isPrivateVisible">
                <a [routerLink]="['', { outlets: { modal: ['new-module', 'private'] } }]">
                    <div class="white h-100-p p-16">
                        <mat-icon svgIcon="course_private"></mat-icon>
                        <span class="card-title">{{ 'NAV.NEW.PRIVATE_MODULE.TITLE' | translate }}</span>
                        <span class="card-description">{{ 'NAV.NEW.PRIVATE_MODULE.DESCRIPTION' | translate }}</span>
                    </div>
                </a>
            </div>
            <div class="card mb-24" fxFlex="1 1 50%" *ngIf="isELearningVisible">
                <a [routerLink]="['', { outlets: { modal: ['new-module', 'e-learning'] } }]">
                    <div class="white h-100-p p-16">
                        <mat-icon svgIcon="e_learning"></mat-icon>
                        <span class="card-title">{{ 'NAV.NEW.ELEARNING_MODULE.TITLE' | translate }}</span>
                        <span class="card-description">{{ 'NAV.NEW.ELEARNING_MODULE.DESCRIPTION' | translate }}</span>
                    </div>
                </a>
            </div>
        </div>
        <div *ngSwitchDefault>
            <mat-horizontal-stepper
                class="pt-16 e-learning"
                [class.allow-tags-overflow]="stepper.selectedIndex === 0 && isStepOneActive"
                labelPosition="bottom"
                #stepper
                fusePerfectScrollbar
                [fusePerfectScrollbarOptions]="{ suppressScrollX: true }"
                [ngSwitch]="params.type"
                [class.select-participants-tab]="stepper.selectedIndex === 2"
                (selectionChange)="onStepChange($event)"
            >
                <mat-step *ngSwitchDefault>
                    <ng-template matStepLabel>{{ 'GENERAL.PROPERTY.NAME' | translate }}</ng-template>
                    <form [formGroup]="moduleDetailsForm" class="module w-100-p">
                        <div fxLayout="row" fxLayoutAlign="flex-end center">
                            <mat-form-field class="w-100-p">
                                <input matInput name="name" formControlName="name" [placeholder]="'GENERAL.PROPERTY.NAME' | translate" />
                                <mat-error *ngIf="moduleDetailsForm.controls['name'].errors && moduleDetailsForm.controls['name'].errors['message']">
                                    {{ 'ERROR.RESPONSE.' + moduleDetailsForm.controls['name'].errors['message'] | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <p class="m-0">
                            <span class="input-title">{{ 'TAG.PLURAL' | translate }}</span> ({{ 'GENERAL.PROPERTY.OPTIONAL' | translate | lowercase }})
                        </p>
                        <div class="w-100-p">
                            <mat-form-field class="w-100-p">
                                <input
                                    matInput
                                    [placeholder]="('TAG.NEW' | translate) + '...'"
                                    #tagInput
                                    [formControl]="tagCtrl"
                                    [matAutocomplete]="auto"
                                    [matChipInputFor]="chipList"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="true"
                                    (matChipInputTokenEnd)="addTag($event)"
                                />
                            </mat-form-field>
                            <mat-chip-list attr.aria-label="{{ 'GENERAL.LABEL.TAGS_LIST' | translate }}" class="wrap mb-8" #chipList>
                                <div class="relative" *ngFor="let tag of moduleDetailsForm.value.tags">
                                    <mat-chip [style.background]="tag.color" [selectable]="true" [removable]="true" (removed)="removeTag(tag)" [disableRipple]="true">
                                        <span #chip>{{ tag.name }}</span>
                                        <mat-icon matChipRemove>close</mat-icon>
                                    </mat-chip>
                                    <app-tag-color-card (updated)="tagUpdated($event)" [tag]="tag" [name]="tag.name" [target]="chip"> </app-tag-color-card>
                                </div>
                            </mat-chip-list>
                            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="tagSelected($event)">
                                <mat-optgroup [label]="'TAG.NEW' | translate" *ngIf="!tagTermExists && tagCtrl.value">
                                    <mat-option [value]="tagCtrl.value"><span [innerHTML]="tagCtrl.value | highlight: tagCtrl.value"></span></mat-option>
                                </mat-optgroup>
                                <mat-optgroup
                                    [label]="('GENERAL.SUGGESTIONS' | translate) + ' (' + tags.length + ')'"
                                    *ngIf="(filteredTag$ | async) || [] | exclude: moduleDetailsForm.value.tags || []:'id'; let tags"
                                >
                                    <mat-option *ngFor="let tag of tags | filterTagsByCategory: 'module'" [value]="tag">
                                        <span [innerHTML]="tag.name | highlight: tagCtrl.value"></span>
                                    </mat-option>
                                </mat-optgroup>
                            </mat-autocomplete>
                            <mat-hint>{{ 'PAGE.MODULE_FORM.TAB.OVERVIEW.MESSAGE.TAGS_HINT' | translate }} </mat-hint>
                        </div>
                    </form>
                </mat-step>
                <mat-step>
                    <ng-template matStepLabel>{{ 'GENERAL.PROPERTY.DURATION' | translate }}</ng-template>
                    <form *ngIf="stepper.selectedIndex === 1" [formGroup]="moduleDetailsForm" class="module w-100-p">
                        <div class="mb-24 w-100-p" fxLayout="column">
                            <mat-radio-group class="self-paced-container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="20px" formControlName="selfPaced">
                                <div
                                    *ngIf="params.type !== 'e-learning'"
                                    class="radio-wrapper paced p-16"
                                    [class.selected]="!moduleDetailsForm.controls.selfPaced.value"
                                    fxLayout="column"
                                >
                                    <mat-radio-button [value]="false"></mat-radio-button>
                                    <div fxLayout="column" fxLayoutAlign="center center">
                                        <img height="42" width="42" src="/assets/icons/ic_scheduled_module.svg" />
                                        <span class="mt-12">{{ 'MODULE.SCHEDULED.TITLE' | translate }} </span>
                                        <p>{{ 'MODULE.SCHEDULED.DESCRIPTION' | translate }}</p>
                                    </div>
                                </div>
                                <div class="radio-wrapper paced p-16" [class.selected]="moduleDetailsForm.controls.selfPaced.value" fxLayout="column">
                                    <mat-radio-button [value]="true"></mat-radio-button>
                                    <div fxLayout="column" fxLayoutAlign="center center">
                                        <img height="42" width="42" src="/assets/icons/ic_self_paced_module.svg" />
                                        <span class="mt-12">{{ 'MODULE.SELF_PACED.TITLE' | translate }} </span>
                                        <p>{{ 'MODULE.SELF_PACED.DESCRIPTION' | translate }}</p>
                                    </div>
                                </div>
                            </mat-radio-group>
                        </div>
                        <div *ngIf="!moduleDetailsForm.controls.selfPaced.value">
                            <div fxLayout="row" fxLayoutAlign="flex-end center">
                                <mat-form-field class="w-100-p">
                                    <input
                                        matInput
                                        name="startDate"
                                        [max]="maxDate"
                                        formControlName="startDate"
                                        [matDatepicker]="pickerStartDate"
                                        [placeholder]="'GENERAL.PROPERTY.START_DATE' | translate"
                                        (focus)="pickerStartDate.open()"
                                        (ngModelChange)="updateEndDate()"
                                    />
                                    <mat-datepicker-toggle matSuffix [for]="pickerStartDate"></mat-datepicker-toggle>
                                    <mat-datepicker #pickerStartDate></mat-datepicker>

                                    <mat-error *ngIf="moduleDetailsForm.controls['startDate'].errors && moduleDetailsForm.controls['startDate'].errors['message']">
                                        {{ 'ERROR.RESPONSE.' + moduleDetailsForm.controls['startDate'].errors['message'] | translate }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="flex-end center">
                                <mat-form-field class="w-100-p">
                                    <input
                                        matInput
                                        name="endDate"
                                        [min]="minDate"
                                        formControlName="endDate"
                                        [matDatepicker]="pickerEndDate"
                                        [placeholder]="'GENERAL.PROPERTY.END_DATE' | translate"
                                        (focus)="pickerEndDate.open()"
                                        (ngModelChange)="updateStartDate()"
                                    />
                                    <mat-datepicker-toggle matSuffix [for]="pickerEndDate"></mat-datepicker-toggle>
                                    <mat-datepicker #pickerEndDate></mat-datepicker>
                                    <mat-error *ngIf="moduleDetailsForm.controls['endDate'].errors && moduleDetailsForm.controls['endDate'].errors['message']">
                                        {{ 'ERROR.RESPONSE.' + moduleDetailsForm.controls['endDate'].errors['message'] | translate }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="column" class="mt-16" *ngIf="params.type !== 'e-learning'; else duration">
                            <p class="m-0">
                                <span class="input-title">{{ 'MODULE.MODULE_DURATION.TITLE' | translate }}</span> ({{ 'GENERAL.PROPERTY.OPTIONAL' | translate | lowercase }})
                            </p>
                            <p class="m-0 mb-8">
                                {{ 'MODULE.MODULE_DURATION.DESCRIPTION' | translate }}
                            </p>
                            <div fxLayout="row" fxLayoutAlign="flex-start center" class="duration-wrapper" formGroupName="digicompDuration">
                                <mat-form-field class="duration-field">
                                    <input matInput min="0" type="number" name="year" step="1" [placeholder]="'GENERAL.YEAR' | translate" formControlName="y" />
                                </mat-form-field>
                                <mat-form-field class="duration-field">
                                    <input matInput min="0" type="number" name="month" step="1" [placeholder]="'GENERAL.MONTH' | translate" formControlName="m" />
                                </mat-form-field>
                                <mat-form-field class="duration-field">
                                    <input matInput min="0" type="number" name="week" step="1" [placeholder]="'GENERAL.WEEK' | translate" formControlName="w" />
                                </mat-form-field>
                                <mat-form-field class="duration-field">
                                    <input matInput min="0" type="number" name="day" step="1" [placeholder]="'GENERAL.DAY' | translate" formControlName="d" />
                                </mat-form-field>
                                <mat-form-field class="duration-field">
                                    <input matInput min="0" type="number" name="hour" step="1" [placeholder]="'GENERAL.HOUR' | translate" formControlName="hh" />
                                </mat-form-field>
                                <mat-form-field class="duration-field">
                                    <input matInput min="0" type="number" name="minute" step="1" [placeholder]="'GENERAL.MINUTE' | translate" formControlName="mm" />
                                </mat-form-field>
                            </div>
                        </div>
                        <ng-template #duration>
                            <div fxLayout="row" fxLayoutAlign="flex-end center">
                                <mat-form-field class="w-100-p">
                                    <input
                                        matInput
                                        [cleave]="{ time: true, timePattern: ['h', 'm'] }"
                                        formControlName="duration"
                                        [placeholder]="'GENERAL.PROPERTY.EXPECTED_DURATION' | translate"
                                    />
                                    <mat-icon class="timepicker-icon">access_time</mat-icon>
                                </mat-form-field>
                            </div>
                        </ng-template>
                    </form>
                </mat-step>

                <mat-step *ngIf="params.type !== 'e-learning'">
                    <ng-template matStepLabel> {{ 'PAGE.MODULE_FORM.TAB.CONTENT.PARTICIPANTS_PROGRESS.CONTENT' | translate }}</ng-template>
                    <form *ngIf="stepper.selectedIndex === 2" [formGroup]="moduleContentForm" class="w-100-p pt-16" fxLayout="column">
                        <mat-radio-group
                            class="structured-module-container"
                            fxLayout="row"
                            fxLayout.xs="column"
                            fxLayoutAlign="center"
                            fxLayoutGap="20px"
                            formControlName="structure"
                            (change)="handleChange($event)"
                        >
                            <div
                                class="radio-wrapper content-structure"
                                fxLayout="column"
                                fxLayoutAlign="center"
                                fxLayoutGap="23px"
                                [class.selected]="moduleContentForm.controls.structure.value"
                            >
                                <mat-radio-button [value]="true"> </mat-radio-button>
                                <div fxLayoutAlign="center center" class="img-wrapper w-100-p py-20">
                                    <mat-icon class="sections-and-content-icon" svgIcon="sections_and_content"></mat-icon>
                                </div>
                                <div fxLayout="column" fxLayoutAlign="center" class="px-16 pb-16">
                                    <span class="center">{{ 'FORM.SELECT_CONTENT_STRUCTURE.FIELD.SECTIONS_CONTENT' | translate }}</span>
                                    <p>{{ 'COURSES.PROGRAM.CONTENT.SECTIONS_CONTENT' | translate }}</p>
                                </div>
                            </div>
                            <div
                                class="radio-wrapper content-structure"
                                fxLayout="column"
                                fxLayoutAlign="center"
                                fxLayoutGap="23px"
                                [class.selected]="!moduleContentForm.controls.structure.value"
                            >
                                <mat-radio-button [value]="false"> </mat-radio-button>
                                <div fxLayoutAlign="center center" class="img-wrapper w-100-p pb-20">
                                    <mat-icon class="content-only-icon" svgIcon="content_only"></mat-icon>
                                </div>
                                <div fxLayout="column" fxLayoutAlign="center" class="px-16 pb-16">
                                    <span>{{ 'FORM.SELECT_CONTENT_STRUCTURE.FIELD.CONTENT_ONLY' | translate }}</span>
                                    <p>{{ 'COURSES.PROGRAM.CONTENT.CONTENT_ONLY' | translate }}</p>
                                </div>
                            </div>
                        </mat-radio-group>
                        <mat-divider></mat-divider>
                        <div class="checkbox-wrapper">
                            <mat-radio-group fxLayout="column" formControlName="rule">
                                <mat-radio-button class="mb-8" [value]="ruleOpts.dontTrackProgress">
                                    {{ 'FORM.SELECT_CONTENT_STRUCTURE.FIELD.DONT_TRACK_PROGRESS' | translate }}
                                </mat-radio-button>
                                <mat-radio-button class="mb-8" [value]="ruleOpts.trackProgress">
                                    {{ 'FORM.SELECT_CONTENT_STRUCTURE.FIELD.TRACK_PROGRESS' | translate }}
                                </mat-radio-button>
                                <mat-radio-button [value]="ruleOpts.sequential">
                                    {{ 'FORM.SELECT_CONTENT_STRUCTURE.FIELD.TRACK_PROGRESS_AND_ENABLE_SEQUENTIAL_ACCESS' | translate }}
                                </mat-radio-button>
                                <span class="description">{{ 'FORM.SELECT_CONTENT_STRUCTURE.FIELD.SEQUENTIAL_DESCRIPTION' | translate }}</span>
                            </mat-radio-group>
                        </div>
                    </form>
                </mat-step>
                <mat-step>
                    <ng-template matStepLabel>{{ 'PAGE.MODULE_FORM.TAB.MANAGERS.TITLE' | translate }}</ng-template>
                    <div
                        *ngIf="stepper.selectedIndex === 3 || (stepper.selectedIndex === 2 && params.type === 'e-learning')"
                        class="select-users-wrapper"
                        fxLayout="column"
                        fxLayoutAlign="start"
                    >
                        <ng-container *ngIf="list.length">
                            <h3 class="mb-16 text-base font-medium text-gray-600">{{ 'GENERAL.LABEL.SELECTED_USERS' | translate }}</h3>
                            <mat-chip-list attr.aria-label="{{ 'GENERAL.LABEL.TAGS_LIST' | translate }}" class="chip-list wrap">
                                <mat-chip class="chip" [removable]="removable" (removed)="removeFromList(item)" *ngFor="let item of list">
                                    {{ item.fullName }}
                                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                </mat-chip>
                            </mat-chip-list>
                        </ng-container>

                        <div fxLayout="row" fxLayoutAlign="flex-end center" class="mb-12">
                            <app-search-outline [searchInput]="searchInput"></app-search-outline>
                        </div>

                        <div class="w-100-p table-wrapper" *ngIf="dataSource">
                            <mat-table #table [dataSource]="dataSource" matSort [@animateStagger]="{ value: '50' }">
                                <!-- Avatar Column -->
                                <ng-container matColumnDef="avatar">
                                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                                    <mat-cell *matCellDef="let user">
                                        <div #avatarCell>
                                            <img class="avatar" *ngIf="user?.avatar.src; else initials" [alt]="user.fullName" [src]="user.avatar.src" />
                                            <ng-template #initials>
                                                <div fxLayoutAlign="center center" class="avatar-initials avatar">{{ user.fullName | initials }}</div>
                                            </ng-template>
                                        </div>
                                    </mat-cell>
                                </ng-container>

                                <!-- First Name Column -->
                                <ng-container matColumnDef="firstName">
                                    <mat-header-cell class="ml-16 narrow" *matHeaderCellDef mat-sort-header> {{ 'GENERAL.PROPERTY.FIRST_NAME' | translate }}</mat-header-cell>
                                    <mat-cell *matCellDef="let user" class="ml-16 narrow">
                                        <p #firstNameRow class="text-truncate font-semibold">
                                            {{ user.firstName }}
                                        </p>
                                    </mat-cell>
                                </ng-container>

                                <!-- Last Name Column -->
                                <ng-container matColumnDef="lastName">
                                    <mat-header-cell class="narrow" *matHeaderCellDef mat-sort-header>
                                        {{ 'GENERAL.PROPERTY.LAST_NAME' | translate }}
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let user" class="narrow">
                                        <p #lastNameRow class="text-truncate font-semibold">
                                            {{ user.lastName }}
                                        </p>
                                    </mat-cell>
                                </ng-container>

                                <!-- Email Column -->
                                <ng-container matColumnDef="email">
                                    <mat-header-cell class="wider" *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>
                                        {{ 'GENERAL.PROPERTY.EMAIL' | translate }}
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let user" class="wider" fxHide fxShow.gt-xs>
                                        <p #lastNameRow class="text-truncate font-semibold">{{ user.email }}</p>
                                    </mat-cell>
                                </ng-container>

                                <!-- Buttons Column -->
                                <ng-container matColumnDef="buttons">
                                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                                    <mat-cell *matCellDef="let user">
                                        <div fxFlex="row" fxLayoutAlign="end center">
                                            <app-flat-button
                                                [label]="'GENERAL.BUTTON.ADD'"
                                                (onClick)="addToList(user)"
                                                *ngIf="!user.alreadyMember && !user.selected"
                                            ></app-flat-button>
                                            <app-white-button [label]="'GENERAL.BUTTON.UNSELECT'" (onClick)="removeFromList(user)" *ngIf="user.selected"></app-white-button>
                                        </div>
                                    </mat-cell>
                                </ng-container>

                                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                <mat-row *matRowDef="let user; columns: displayedColumns" class="user" matRipple [@animate]="{ value: '*', params: { y: '100%' } }"> </mat-row>
                            </mat-table>
                            <mat-paginator [length]="totalCount$ | async" [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 50]"> </mat-paginator>
                        </div>
                    </div>
                </mat-step>
            </mat-horizontal-stepper>

            <div
                mat-dialog-actions
                class="pt-24 action-buttons"
                fxLayout="row"
                fxLayout.xs="column"
                fxLayoutGap.xs="20px"
                fxLayoutAlign="space-between center"
                fxLayoutAlign.xs="start space-between"
            >
                <mat-checkbox [(ngModel)]="createAnotherChecked">
                    {{ 'MODULE.CREATE_ANOTHER' | translate }}
                </mat-checkbox>
                <div fxLayoutAlign="end center">
                    <app-white-button class="mr-8" [disabled]="stepper.selectedIndex === 0" [label]="'GENERAL.BUTTON.PREVIOUS'" (onClick)="goBack(stepper)"></app-white-button>
                    <app-white-button
                        class="mr-8"
                        [disabled]="!moduleDetailsForm.controls['name'].value || (stepper.steps && stepper.selectedIndex === stepper.steps.length - 1)"
                        [label]="'GENERAL.BUTTON.NEXT'"
                        (onClick)="goForward(stepper)"
                    ></app-white-button>
                    <app-flat-button
                        [label]="'GENERAL.BUTTON.CREATE'"
                        [disabled]="moduleDetailsForm.invalid || moduleDetailsForm.pristine"
                        (onClick)="create(params.type)"
                    ></app-flat-button>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #spinner>
    <div class="p-24" fxLayout="column" fxLayoutAlign="center center">
        <div class="p-24">
            <mat-spinner></mat-spinner>
        </div>
        <p class="center">{{ 'MODULE.MESSAGE.CREATING' | translate }}.<br />{{ 'GENERAL.MESSAGE.PLEASE_WAIT' | translate }}.</p>
    </div>
</ng-template>

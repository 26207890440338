import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UnsubscribeOnDestroy } from 'app/projects/core/src/lib/models/unsubscribe-on-destroy';
import { fuseAnimations } from 'app/projects/fuse/src/lib/animations';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-name-dialog',
    templateUrl: './name.component.html',
    styleUrls: ['./name.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class NameDialogComponent extends UnsubscribeOnDestroy {
    @Input()
    dialogTitle: string;

    @Input()
    placeholder: string;

    @Input()
    label?: string;

    @Input()
    isSocialListVisible = false;

    @Input()
    showSpinner = false;

    @Input()
    set addEmailValidation(value: boolean) {
        if (value) {
            this.nameCtrl.setValidators([Validators.email]);
        }

        this.nameCtrl.updateValueAndValidity(); // Trigger validation update
    }

    @Input()
    set name(value: string) {
        this.nameCtrl.setValue(value);
    }

    nameCtrl = new FormControl('', [Validators.required]);

    @Output()
    save = new EventEmitter<string>();

    errors = new BehaviorSubject<{ message: string }>(null);

    constructor(public dialogRef: MatDialogRef<NameDialogComponent>) {
        super();

        this.errors
            .asObservable()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((error) => {
                this.nameCtrl.setErrors(error);
            });
    }
}

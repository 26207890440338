import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { TenantDataService } from 'app/config/services/tenant-data.service';
import { MAXBRAIN_ENVIRONMENT } from 'app/projects/core/src/lib/services/environment.token';
import { MaxBrainUtils } from 'app/projects/core/src/lib/utils';
import { fuseAnimations } from 'app/projects/fuse/src/lib/animations';
import { FeatureSwitchName } from 'app/projects/shared/src/lib/enums/feature-switch.enum';
import { FeatureSwitchPipe } from 'app/projects/shared/src/lib/pipes/feature-switch.pipe';
import PSPDFKit from 'pspdfkit/dist/pspdfkit';
import { IMaxBrainEnvironment } from '../../interfaces/environment.interface';

@Component({
    selector: 'app-pdf-web-viewer',
    templateUrl: './pdf-web-viewer.component.html',
    styleUrls: ['./pdf-web-viewer.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class PdfWebViewerComponent implements OnInit {
    @Input()
    pdf: string;

    @Output()
    load = new EventEmitter<PSPDFKit.Instance>();

    isCustomDomainFeatureEnabled: boolean;

    constructor(@Inject(MAXBRAIN_ENVIRONMENT) private environment: IMaxBrainEnvironment, private _featureSwitchPipe: FeatureSwitchPipe, private _tenantData: TenantDataService) {
        this.isCustomDomainFeatureEnabled = this._featureSwitchPipe.transform(FeatureSwitchName.CustomDomain);
    }

    ngOnInit(): void {
        PSPDFKit.load({
            baseUrl: MaxBrainUtils.baseUrl + '/assets/',
            container: '#pspdfkit',
            document: this.pdf,
            licenseKey: this.isCustomDomainFeatureEnabled ? this._tenantData.tenantConfig.pspdfkit_license.pspdfkit : this.environment.PSPDFKitLicenseKey,
            disableWebAssemblyStreaming: true,
        })
            .then((instance: PSPDFKit.Instance) => {
                instance.setViewState(instance.viewState.set('zoom', 'FIT_TO_WIDTH'));

                this.load.emit(instance);
            })
            .catch((error) => {
                console.error(error.message);
            });
    }
}

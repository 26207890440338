import { IStatus } from 'app/projects/shared/src/lib/interfaces/status.interface';
import { SectionRule } from '../enums/sectionRule.enum';

// @dynamic
export class ModuleStatuses {
    static visibleKeys: [string, string, string, string, string] = ['unpublished', 'published', 'running', 'completed', 'archived'];

    static all: IStatus[] = [
        {
            name: 'unpublished',
            translationLabel: 'MODULE.STATUS.UNPUBLISHED',
            possiblePreviousStatusNames: ['published', 'running'],
            primaryColor: '#F5A622',
            secundaryColor: '#EDA027',
        },
        {
            name: 'published',
            translationLabel: 'MODULE.STATUS.PUBLISHED',
            possiblePreviousStatusNames: ['unpublished', 'pending', 'running', 'canceled'],
            primaryColor: '#CF7EFF',
            secundaryColor: '#C87BF6',
        },
        {
            name: 'running',
            translationLabel: 'MODULE.STATUS.RUNNING',
            possiblePreviousStatusNames: ['published', 'completed', 'canceled'],
            primaryColor: '#61B3F2',
            secundaryColor: '#60ADE8',
        },
        {
            name: 'completed',
            translationLabel: 'MODULE.STATUS.COMPLETED',
            possiblePreviousStatusNames: ['running', 'published', 'archived', 'canceled'],
            primaryColor: '#4DDA8D',
            secundaryColor: '#4DD289',
        },
        {
            name: 'canceled', // BC
            translationLabel: 'MODULE.STATUS.CANCELED',
            possiblePreviousStatusNames: [],
            primaryColor: '#EE5568',
            secundaryColor: '#E65466',
        },
        {
            name: 'archived',
            translationLabel: 'MODULE.STATUS.ARCHIVED',
            possiblePreviousStatusNames: ['completed', 'canceled'],
            primaryColor: '#ADAFB4',
            secundaryColor: '#A8AAAD',
        },
    ];

    static ruleOpts: {
        dontTrackProgress: string;
        trackProgress: string;
        sequential: string;
    } = {
        dontTrackProgress: SectionRule.Default,
        trackProgress: SectionRule.TrackProgress,
        sequential: SectionRule.Sequential,
    };

    static getNextAvailableStatuses(moduleStatus: string): IStatus[] {
        return ModuleStatuses.all.filter((status) => status.possiblePreviousStatusNames.includes(moduleStatus));
    }
}

import { Sort } from '@angular/material/sort';
import { User } from 'app/projects/user/src/lib/models/user';
import { ExtendedAttributeFilterInterface } from '../interfaces/extended-attribute-filer.interface';

export class SetAllUsers {
    static readonly type = '[AllUsers] SetAllUsers';

    constructor(public payload: User[]) {}
}

export class UnsetAllUsers {
    static readonly type = '[AllUsers] UnsetAllUsers';
}

export class FetchParticipantsAndResponsibles {
    static readonly type = '[AllUsers] FetchParticipantsAndResponsibles';

    constructor(
        public payload?: {
            moduleId: string;
            groupId?: string;
            search?: string;
            sortBy?: string;
            sortOrder?: string;
            pageSize?: number;
            pageNumber?: number;
        }
    ) {}
}

export class FetchParticipantsAndResponsiblesSuccess {
    static readonly type = '[AllUsers] FetchParticipantsAndResponsiblesSuccess';

    constructor(public payload: User[]) {}
}

export class FetchParticipantsAndResponsiblesFailure {
    static readonly type = '[AllUsers] FetchParticipantsAndResponsiblesFailure';
}

export class FetchRootAudienceUsers {
    static readonly type = '[AllUsers] FetchRootAudienceUsers';

    constructor(
        public payload?: {
            audienceId: string;
            search?: string;
            sortBy?: string;
            sortOrder?: string;
            pageSize?: number;
            pageNumber?: number;
        }
    ) {}
}

export class FetchRootAudienceUsersSuccess {
    static readonly type = '[AllUsers] FetchRootAudienceUsersSuccess';

    constructor(public payload: User[]) {}
}

export class FetchRootAudienceUsersFailure {
    static readonly type = '[AllUsers] FetchRootAudienceUsersFailure';
}

export class FetchAvailableUsers {
    static readonly type = '[AllUsers] FetchAvailableUsers';

    constructor(
        public payload?: {
            groupId: string;
            search: string;
            sortBy: string;
            sortOrder: string;
            pageSize: number;
            pageNumber: number;
        }
    ) {}
}

export class FetchAvailableUsersSuccess {
    static readonly type = '[AllUsers] FetchAvailableUsersSuccess';

    constructor(public payload: User[]) {}
}

export class FetchAvailableUsersFailure {
    static readonly type = '[AllUsers] FetchAvailableUsersFailure';
}

export class FetchAllUsers {
    static readonly type = '[AllUsers] FetchAllUsers';

    constructor(
        public payload?: {
            moduleIds?: string[];
            entityId?: string;
            search?: string;
            sortBy?: string;
            sortOrder?: string;
            pageSize?: number;
            pageNumber?: number;
            isUnassignedSelected?: boolean;
            role?: string;
            includeAnalytics?: boolean;
            experienceIds?: string[];
            audienceIds?: string[];
            teamIds?: string[];
            extendedAttributesFilters?: ExtendedAttributeFilterInterface[];
            isUnassignedSelectedExperiences?: boolean;
            isUnassignedSelectedAudiences?: boolean;
            isUnassignedSelectedTeams?: boolean;
            columns?: string[];
            userRole?: string[];
        }
    ) {}
}

export class FetchAllUsersSuccess {
    static readonly type = '[AllUsers] FetchAllUsersSuccess';

    constructor(public payload: User[]) {}
}

export class FetchAllUsersFailure {
    static readonly type = '[AllUsers] FetchAllUsersFailure';
}

export class ToggleSelectedUser {
    static readonly type = '[AllUsers] ToggleSelectedUser';

    constructor(public payload: string) {}
}

export class SelectFilteredUsers {
    static readonly type = '[AllUsers] SelectFilteredUsers';
}

export class DeselectSelectedUsers {
    static readonly type = '[AllUsers] DeselectSelectedUsers';
}

export class SearchAllUsers {
    static readonly type = '[AllUsers] SearchAllUsers';

    constructor(public payload: string) {}
}

export class RemoveSearchAllUsers {
    static readonly type = '[AllUsers] RemoveSearchAllUsers';

    constructor(public payload: string) {}
}

export class SortAllUsers {
    static readonly type = '[AllUsers] SortAllUsers';

    constructor(public payload: Sort) {}
}

export class SetChatUsers {
    static readonly type = '[ChatUsers] SetChatUsers';

    constructor(public payload: User[]) {}
}

export class UnsetChatUsers {
    static readonly type = '[ChatUsers] UnsetChatUsers';
}

export class FetchChatUsers {
    static readonly type = '[ChatUsers] FetchChatUsers';

    constructor(
        public payload?: {
            moduleIds?: string[];
            entityId?: string;
            search?: string;
            sortBy?: string;
            sortOrder?: string;
            pageSize?: number;
            pageNumber?: number;
            isUnassignedSelected?: boolean;
            role?: string;
            includeAnalytics?: boolean;
            experienceIds?: string[];
            audienceIds?: string[];
            teamIds?: string[];
            extendedAttributesFilters?: ExtendedAttributeFilterInterface[];
            isUnassignedSelectedExperiences?: boolean;
            isUnassignedSelectedAudiences?: boolean;
            isUnassignedSelectedTeams?: boolean;
            columns?: string[];
            userRole?: string[];
        }
    ) {}
}

export class FetchChatUsersSuccess {
    static readonly type = '[ChatUsers] FetchChatUsersSuccess';

    constructor(public payload: User[]) {}
}

export class FetchChatUsersFailure {
    static readonly type = '[ChatUsers] FetchChatUsersFailure';
}

export class SearchChatUsers {
    static readonly type = '[ChatUsers] SearchChatUsers';

    constructor(public payload: string) {}
}

export class RemoveSearchChatUsers {
    static readonly type = '[ChatUsers] RemoveSearchChatUsers';

    constructor(public payload: string) {}
}

export class SortChatUsers {
    static readonly type = '[ChatUsers] SortChatUsers';

    constructor(public payload: Sort) {}
}

export class FetchAvailableChatUsers {
    static readonly type = '[ChatUsers] FetchAvailableChatUsers';

    constructor(
        public payload?: {
            groupId: string;
            search: string;
            sortBy: string;
            sortOrder: string;
            pageSize: number;
            pageNumber: number;
        }
    ) {}
}

export class FetchAvailableChatUsersSuccess {
    static readonly type = '[ChatUsers] FetchAvailableChatUsersSuccess';

    constructor(public payload: User[]) {}
}

export class FetchAvailableChatUsersFailure {
    static readonly type = '[ChatUsers] FetchAvailableChatUsersFailure';
}

export class FetchCourseOfferingUsers {
    static readonly type = '[AllUsers] FetchCourseOfferingUsers';

    constructor(
        public payload?: {
            entityId: string;
            search?: string;
            sortBy?: string;
            sortOrder?: string;
            pageSize?: number;
            pageNumber?: number;
        }
    ) {}
}

export class FetchCourseOfferingUsersSuccess {
    static readonly type = '[AllUsers] FetchCourseOfferingUsersSuccess';

    constructor(public payload: User[]) {}
}

export class FetchCourseOfferingUsersFailure {
    static readonly type = '[AllUsers] FetchCourseOfferingUsersFailure';
}

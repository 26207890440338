<app-dialog-header [title]="dialogTitle" (onClick)="dialogRef.close(false)"></app-dialog-header>

<div mat-dialog-content class="p-24 pb-0" fxLayout="column" fxLayoutAlign="start">
    <div class="social-list" fxLayoutAlign="space-between center" *ngIf="isSocialListVisible">
        <img src="https://s3.maxbrain.com/edu-public/facebook.png" alt="Facebook" />
        <img src="https://s3.maxbrain.com/edu-public/linkedin.png" alt="LinkedIn" />
        <img src="https://s3.maxbrain.com/edu-public/xing.png" alt="Xing" />
        <img src="https://s3.maxbrain.com/edu-public/twitter.png" alt="Twitter" />
        <img src="https://s3.maxbrain.com/edu-public/instagram.png" alt="Instagram" />
        <img src="https://s3.maxbrain.com/edu-public/TikTok.png" alt="TikTok" />
    </div>
    <p class="basic-text mb-24 mt-8" *ngIf="label">{{ label }}</p>
    <mat-form-field>
        <input matInput noWhitespace [placeholder]="placeholder | translate" [formControl]="nameCtrl" (keydown.enter)="save.emit(nameCtrl.value)" />
        <!-- we're hiding FormControl 'required' error and showing API errors -->
        <mat-error *ngIf="nameCtrl.errors && !nameCtrl.hasError('required')">{{ 'ERROR.RESPONSE.' + nameCtrl.errors['message'] | translate }}</mat-error>
    </mat-form-field>
</div>
<div mat-dialog-actions class="pt-24 action-buttons" fxLayout="row" fxLayoutAlign="end center">
    <app-white-button class="mr-8" [label]="'GENERAL.BUTTON.CANCEL'" (onClick)="dialogRef.close(false)"></app-white-button>
    <div class="button-container">
        <div class="spinner-container" *ngIf="showSpinner">
            <mat-spinner diameter="24"></mat-spinner>
        </div>

        <app-flat-button [label]="'GENERAL.BUTTON.SAVE'" (onClick)="save.emit(nameCtrl.value.trim())" [disabled]="!nameCtrl.valid || showSpinner"></app-flat-button>
    </div>
</div>

import { HttpErrorResponse } from '@angular/common/http';
import { ModuleCategory } from 'app/library/module/enums/moduleCategory';
import { IFetchEntityFailureActionPayload } from 'app/projects/entity/src/lib/interfaces/fetch-entity-failure-payload.interface';
import { IManageModuleUsersActionPayload } from '../interfaces/modules-users-action.action-payload.interface';
import { IManageModuleUsersResponsibleActionPayload } from '../interfaces/modules-users-responsible-action.action-payload.interface';
import { IManageTemplateUsersActionPayload } from '../interfaces/templates-users-action.action-payload.interface';
import { IManageTemplateUsersResponsibleActionPayload } from '../interfaces/templates-users-responsible-action.action-payload.interface';
import { ModuleUserExtended } from '../models/module-user.extended.model';
import { ModuleUser } from '../models/module-user.model';
import { ResponsibleRole } from '../models/responsible-role.model';
import { IManageProgramExecutionUsersActionPayload } from '../interfaces/program-execution-users-action.action-payload.interface';

export class SetModuleUser {
    static readonly type = '[ModuleUser] SetModuleUser';

    constructor(public payload: ModuleUser) {}
}

export class UnsetModuleUser {
    static readonly type = '[ModuleUser] UnsetModuleUser';
}

export class FetchModuleUser {
    static readonly type = '[ModuleUser] FetchModuleUser';

    constructor(public payload: string) {}
}

export class FetchModuleUserSuccess {
    static readonly type = '[ModuleUser] FetchModuleUserSuccess';

    constructor(public payload: ModuleUser) {}
}

export class FetchModuleUserFailure {
    static readonly type = '[ModuleUser] FetchModuleUserFailure';

    constructor(public payload: IFetchEntityFailureActionPayload) {}
}

export class UpdateModuleSectionProgressStatus {
    static readonly type = '[ModuleUser] UpdateModuleSectionProgressStatus';

    constructor(public payload: { moduleId: string; action: string }) {}
}

export class UpdateModuleSectionProgressStatusSuccess {
    static readonly type = '[ModuleUser] UpdateModuleSectionProgressStatusSuccess';

    constructor(public payload: ModuleUser[]) {}
}

export class UpdateModuleSectionProgressStatusFailure {
    static readonly type = '[ModuleUser] UpdateModuleSectionProgressStatusFailure';

    constructor(
        public payload: {
            response: HttpErrorResponse;
        }
    ) {}
}

export class UpdateModuleUser {
    static readonly type = '[ModuleUser] UpdateModuleUser';

    constructor(public payload: IManageModuleUsersActionPayload) {}
}

export class UpdateModuleUserSuccess {
    static readonly type = '[ModuleUser] UpdateModuleUserSuccess';

    constructor(public payload: ModuleUser[]) {}
}

export class UpdateModuleUserFailure {
    static readonly type = '[ModuleUser] UpdateModuleUserFailure';

    constructor(
        public payload: {
            response: HttpErrorResponse;
        }
    ) {}
}

export class UpdateTemplateUser {
    static readonly type = '[ModuleUser] UpdateTemplateUser';

    constructor(public payload: IManageModuleUsersActionPayload) {}
}

export class UpdateTemplateUserSuccess {
    static readonly type = '[ModuleUser] UpdateTemplateUserSuccess';

    constructor(public payload: ModuleUser[]) {}
}

export class UpdateTemplateUserFailure {
    static readonly type = '[ModuleUser] UpdateTemplateUserFailure';

    constructor(
        public payload: {
            response: HttpErrorResponse;
        }
    ) {}
}

export class UpdateModuleUserResponsible {
    static readonly type = '[ModuleUser] UpdateModuleUserResponsible';

    constructor(public payload: IManageModuleUsersResponsibleActionPayload) {}
}

export class UpdateModuleUserResponsibleSuccess {
    static readonly type = '[ModuleUser] UpdateModuleUserResponsibleSuccess';

    constructor(public payload: ModuleUser[]) {}
}

export class UpdateModuleUserResponsibleFailure {
    static readonly type = '[ModuleUser] UpdateModuleUserResponsibleFailure';

    constructor(
        public payload: {
            response: HttpErrorResponse;
        }
    ) {}
}

export class UpdateTemplateUserResponsible {
    static readonly type = '[ModuleUser] UpdateTemplateUserResponsible';

    constructor(public payload: IManageTemplateUsersResponsibleActionPayload) {}
}

export class UpdateTemplateUserResponsibleSuccess {
    static readonly type = '[ModuleUser] UpdateTemplateUserResponsibleSuccess';

    constructor(public payload: ModuleUser[]) {}
}

export class UpdateTemplateUserResponsibleFailure {
    static readonly type = '[ModuleUser] UpdateTemplateUserResponsibleFailure';

    constructor(
        public payload: {
            response: HttpErrorResponse;
        }
    ) {}
}

export class RemoveUserAsResponsible {
    static readonly type = '[ModuleUser] RemoveUserAsResponsible';

    constructor(public payload: IManageModuleUsersResponsibleActionPayload) {}
}

export class RemoveUserAsResponsibleSuccess {
    static readonly type = '[ModuleUser] RemoveUserAsResponsibleSuccess';
}

export class RemoveUserAsResponsibleFailure {
    static readonly type = '[ModuleUser] RemoveUserAsResponsibleFailure';

    constructor(
        public payload: {
            response: HttpErrorResponse;
        }
    ) {}
}

export class RemoveTemplateUserAsResponsible {
    static readonly type = '[ModuleUser] RemoveTemplateUserAsResponsible';

    constructor(public payload: IManageTemplateUsersResponsibleActionPayload) {}
}

export class RemoveTemplateUserAsResponsibleSuccess {
    static readonly type = '[ModuleUser] RemoveTemplateUserAsResponsibleSuccess';
}

export class RemoveTemplateUserAsResponsibleFailure {
    static readonly type = '[ModuleUser] RemoveTemplateUserAsResponsibleFailure';

    constructor(
        public payload: {
            response: HttpErrorResponse;
        }
    ) {}
}

export class UpdateModuleUserPinnedStatus {
    static readonly type = '[ModuleUser] UpdateModuleUserPinnedStatus';

    constructor(public payload: { moduleId: string; isPinned: boolean; listType?: ModuleCategory }) {}
}

export class UpdateModuleUserPinnedStatusSuccess {
    static readonly type = '[ModuleUser] UpdateModuleUserPinnedStatusSuccess';

    constructor(public payload: { moduleUserExtended: ModuleUserExtended; isPinned: boolean; listType?: ModuleCategory }) {}
}

export class UpdateModuleUserPinnedStatusFailure {
    static readonly type = '[ModuleUser] UpdateModuleUserPinnedStatusFailure';
}

export class AddUsersToModulesAsParticipants {
    static readonly type = '[ModuleUser] AddUsersToModulesAsParticipants';

    constructor(public payload: IManageModuleUsersActionPayload) {}
}

export class AddUsersToModulesAsParticipantsSuccess {
    static readonly type = '[ModuleUser] AddUsersToModulesAsParticipantsSuccess';

    constructor(public payload: ModuleUser[]) {}
}

export class AddUsersToModulesAsParticipantsFailure {
    static readonly type = '[ModuleUser] AddUsersToModulesAsParticipantsFailure';

    constructor(
        public payload: {
            response: HttpErrorResponse;
        }
    ) {}
}

export class AddUsersToProgramExecutionsAsParticipants {
    static readonly type = '[ModuleUser] AddUsersToProgramExecutionsAsParticipants';

    constructor(public payload: IManageProgramExecutionUsersActionPayload) {}
}

export class AddUsersToProgramExecutionsAsParticipantsSuccess {
    static readonly type = '[ModuleUser] AddUsersToProgramExecutionsAsParticipantsSuccess';

    constructor(
        public payload: {
            asyncJobId: string;
        }
    ) {}
}

export class AddUsersToProgramExecutionsAsParticipantsFailure {
    static readonly type = '[ModuleUser] AddUsersToProgramExecutionsAsParticipantsFailure';

    constructor(
        public payload: {
            response: HttpErrorResponse;
        }
    ) {}
}

export class AddUsersToCourseOfferings {
    static readonly type = '[ModuleUser] AddUsersToCourseOfferings';

    constructor(public payload: IManageProgramExecutionUsersActionPayload) {}
}

export class AddUsersToCourseOfferingsSuccess {
    static readonly type = '[ModuleUser] AddUsersToCourseOfferingsSuccess';

    constructor(
        public payload: {
            asyncJobId: string;
        }
    ) {}
}

export class AddUsersToCourseOfferingsFailure {
    static readonly type = '[ModuleUser] AddUsersToCourseOfferingsFailure';

    constructor(
        public payload: {
            response: HttpErrorResponse;
        }
    ) {}
}

export class AddUsersToModulesAsManagers {
    static readonly type = '[ModuleUser] AddUsersToModulesAsManagers';

    constructor(public payload: IManageModuleUsersActionPayload) {}
}

export class AddUsersToModulesAsManagersSuccess {
    static readonly type = '[ModuleUser] AddUsersToModulesAsManagersSuccess';

    constructor(public payload: ModuleUser[]) {}
}

export class AddUsersToModulesAsManagersFailure {
    static readonly type = '[ModuleUser] AddUsersToModulesAsManagersFailure';

    constructor(
        public payload: {
            response: HttpErrorResponse;
        }
    ) {}
}

export class AddUsersToTemplatesAsManagers {
    static readonly type = '[ModuleUser] AddUsersToTemplatesAsManagers';

    constructor(public payload: IManageTemplateUsersActionPayload) {}
}

export class AddUsersToTemplatesAsManagersSuccess {
    static readonly type = '[ModuleUser] AddUsersToTemplatesAsManagersSuccess';

    constructor(public payload: ModuleUser[]) {}
}

export class AddUsersToTemplatesAsManagersFailure {
    static readonly type = '[ModuleUser] AddUsersToTemplatesAsManagersFailure';

    constructor(
        public payload: {
            response: HttpErrorResponse;
        }
    ) {}
}

export class RemoveUsersFromModulesAsParticipants {
    static readonly type = '[ModuleUser] RemoveUsersFromModulesAsParticipants';

    constructor(public payload: IManageModuleUsersActionPayload) {}
}

export class RemoveUsersFromModulesAsParticipantsSuccess {
    static readonly type = '[ModuleUser] RemoveUsersFromModulesAsParticipantsSuccess';

    constructor(public payload: ModuleUser[]) {}
}

export class RemoveUsersFromModulesAsParticipantsFailure {
    static readonly type = '[ModuleUser] RemoveUsersFromModulesAsParticipantsFailure';

    constructor(
        public payload: {
            response: HttpErrorResponse;
        }
    ) {}
}

export class RemoveUsersFromModulesAsManagers {
    static readonly type = '[ModuleUser] RemoveUsersFromModulesAsManagers';

    constructor(public payload: IManageModuleUsersActionPayload) {}
}

export class RemoveUsersFromModulesAsManagersSuccess {
    static readonly type = '[ModuleUser] RemoveUsersFromModulesAsManagersSuccess';

    constructor(public payload: ModuleUser[]) {}
}

export class RemoveUsersFromModulesAsManagersFailure {
    static readonly type = '[ModuleUser] RemoveUsersFromModulesAsManagersFailure';

    constructor(
        public payload: {
            response: HttpErrorResponse;
        }
    ) {}
}

export class RemoveUsersFromTemplatesAsManagers {
    static readonly type = '[ModuleUser] RemoveUsersFromTemplatesAsManagers';

    constructor(public payload: IManageTemplateUsersActionPayload) {}
}

export class RemoveUsersFromTemplatesAsManagersSuccess {
    static readonly type = '[ModuleUser] RemoveUsersFromTemplatesAsManagersSuccess';

    constructor() {}
}

export class RemoveUsersFromTemplatesAsManagersFailure {
    static readonly type = '[ModuleUser] RemoveUsersFromTemplatesAsManagersFailure';

    constructor(
        public payload: {
            response: HttpErrorResponse;
        }
    ) {}
}

export class FetchResponsibleRoles {
    static readonly type = '[ModuleUser] FetchResponsibleRoles';
}

export class FetchResponsibleRolesSuccess {
    static readonly type = '[ModuleUser] FetchResponsibleRolesSuccess';

    constructor(public payload: ResponsibleRole[]) {}
}

export class FetchResponsibleRolesFailure {
    static readonly type = '[ModuleUser] FetchResponsibleRolesFailure';
}

export class RefreshModuleUsersOnlineState {
    static readonly type = '[ModuleUser] RefreshModuleUsersOnlineState';

    constructor(public payload?: ModuleUserExtended[]) {}
}

export class AddSelfAsParticipant {
    static readonly type = '[ModuleUser] AddSelfAsParticipant';

    constructor(public payload: string) {}
}

export class RemoveSelfAsParticipant {
    static readonly type = '[ModuleUser] RemoveSelfAsParticipant';

    constructor(public payload: string) {}
}

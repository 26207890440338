import { Injectable } from '@angular/core';
import { Actions, ofActionDispatched, Select, Store } from '@ngxs/store';
import { NgxsActionHelper } from 'app/projects/core/src/lib/services/action.helper';
import { BehaviorSubject, Observable, race } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
    CreateExperience,
    CreateExperienceSuccess,
    DeleteExperience,
    DeleteExperienceFailure,
    DeleteExperienceSuccess,
    FetchExperiences,
    FetchExperiencesFailure,
    FetchExperiencesSuccess,
    UpdateExperience,
    UpdateExperienceFailure,
    UpdateExperienceSuccess,
} from '../actions/index';
import { MaxBrainExperience } from '../models/experience.model';
import { ExperienceState } from '../models/experience.state';
import { ExperiencesState } from '../models/experiences.state';

@Injectable()
export class ExperienceService {
    @Select(ExperienceState.getEntities)
    experience$: Observable<MaxBrainExperience[]>;

    @Select(ExperiencesState.getFilteredEntities)
    filteredExperience$: Observable<MaxBrainExperience[]>;

    private _newExperienceSubject = new BehaviorSubject<MaxBrainExperience>(null);
    public newExperienceSubject$ = this._newExperienceSubject.asObservable();

    private _saveExperienceChanges = new BehaviorSubject<boolean>(null);
    public saveExperienceChangesSubject$ = this._saveExperienceChanges.asObservable();

    constructor(private _store: Store, private _action$: Actions, private _actionHelper: NgxsActionHelper) {}

    getExperiences(): MaxBrainExperience[] {
        return this._store.selectSnapshot(ExperiencesState.getFilteredEntities);
    }

    getExperienceById(id: string): MaxBrainExperience {
        return ExperienceState.getEntity(this._store.selectSnapshot(ExperienceState), id);
    }

    saveExperienceChanges(value: boolean): void {
        this._saveExperienceChanges.next(value);
    }

    async fetchExperiences(): Promise<boolean> {
        this._store.dispatch(new FetchExperiences());

        return await this._actionHelper.race(FetchExperiencesSuccess, FetchExperiencesFailure).toPromise();
    }

    async createExperience(experience: MaxBrainExperience) {
        this._store.dispatch(new CreateExperience(experience));

        this._action$.pipe(ofActionDispatched(CreateExperienceSuccess), take(1)).subscribe((action: CreateExperienceSuccess) => {
            this._newExperienceSubject.next(action.payload);
        });
    }

    async updateExperience(experience: MaxBrainExperience): Promise<boolean> {
        this._store.dispatch(new UpdateExperience(experience));

        this._action$.pipe(ofActionDispatched(UpdateExperienceSuccess), take(1)).subscribe((action: UpdateExperienceSuccess) => {
            this._newExperienceSubject.next(action.payload);
        });

        return await this._actionHelper.race(UpdateExperienceSuccess, UpdateExperienceFailure).toPromise();
    }

    deleteExperience(experienceId: string): Promise<any> {
        this._store.dispatch(new DeleteExperience(experienceId));

        return race(
            this._action$.pipe(
                ofActionDispatched(DeleteExperienceSuccess),
                map((action: DeleteExperienceSuccess) => action.payload)
            ),
            this._action$.pipe(
                ofActionDispatched(DeleteExperienceFailure),
                map(() => null)
            )
        )
            .pipe()
            .toPromise();
    }
}

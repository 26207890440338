import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import { TenantDataService } from 'app/config/services/tenant-data.service';
import { FetchFeaturesSuccess } from 'app/library/feature/actions';
import { FeatureState } from 'app/library/feature/models/feature.state';
import { FeatureService } from 'app/library/feature/services/feature.service';
import { LeaveGroupSuccess, UpdateGroupSuccess } from 'app/library/group/actions';
import { Group } from 'app/library/group/models/group';
import { LeaveModuleGroupSuccess, UpdateModuleGroupSuccess } from 'app/library/module-group/actions';
import { ModuleGroup } from 'app/library/module-group/models/module-group';
import { Module } from 'app/library/module/models/module.model';
import { MaxBrainAuthState } from 'app/projects/auth/src/lib/models/auth.state';
import { FuseSidebarService } from 'app/projects/fuse/src/lib/components/sidebar/sidebar.service';
import { FuseConfigService } from 'app/projects/fuse/src/lib/services/config.service';
import { ChatConversationType } from 'app/projects/shared/src/lib/enums/chat-conversation-type.enum';
import { FeatureSwitchName } from 'app/projects/shared/src/lib/enums/feature-switch.enum';
import { ILanguage, ILanguageService } from 'app/projects/shared/src/lib/interfaces/language.interface';
import { MAXBRAIN_LANGUAGE_SERVICE } from 'app/projects/shared/src/lib/services/language.token';
import { UpdateUserSuccess } from 'app/projects/user/src/lib/actions/update-entity-success.action';
import { IUserQuery } from 'app/projects/user/src/lib/interfaces/user.query.interface';
import { User } from 'app/projects/user/src/lib/models/user';
import { UserOnlineState } from 'app/projects/user/src/lib/models/user-online-state.model';
import { UserState } from 'app/projects/user/src/lib/models/user.state';
import { UserApiService } from 'app/projects/user/src/lib/services/user.api-service';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import Talk from 'talkjs';

/**
 * https://stackoverflow.com/questions/51528780/typescript-check-typeof-against-custom-type
 */
const stringLitArray = <L extends string>(arr: L[]) => arr;
const initialFetchLimit = 15;

const _conversationOpenedFromContactCard = [
    'contact_card_participants',
    'contact_card_participants_mgr',
    'contact_card_allusers',
    'contact_card_responsible',
    'contact_card_managers',
];
const conversationOpenedFromContactCard = stringLitArray(_conversationOpenedFromContactCard);
export type ConversationOpenedFromContactCard = typeof conversationOpenedFromContactCard[number];
// const isConversationOpenedFromContactCard = (x: any): x is ConversationOpenedFromContactCard => conversationOpenedFromContactCard.includes(x);

const _conversationOpenedFrom = [
    'all_users_row',
    'module_participants_row',
    'user_form',
    'new_sidebar',
    'new_inbox',
    'existing_sidebar',
    'existing_inbox',
    'existing_collapsed',
    'collaboration_tab',
    'global_group',
    ..._conversationOpenedFromContactCard,
];
const conversationOpenedFrom = stringLitArray(_conversationOpenedFrom);
type ConversationOpenedFrom = typeof conversationOpenedFrom[number];
const isConversationOpenedFrom = (x: any): x is ConversationOpenedFrom => conversationOpenedFrom.includes(x);

interface IUpdateConversationData {
    expanded: boolean;
    openedFrom: ConversationOpenedFromContactCard;
}

export interface ICoreConversationData {
    id: string;
    title: string;
    lastMessageRead: boolean;
    lastMessageText: string;
}

export interface IChatPanelConversationData extends ICoreConversationData {
    photoUrl: string;
    // color: string;
    initials: string;
    searchableTerm: string;
}

export interface IChatDrawerConversationData extends ICoreConversationData {
    participant?: User;
    expanded: boolean;
    chatParticipants: IChatParticipant[];
}

interface IFullConversationQuery {
    createdAt: number;
    custom: {
        conversationName: string;
        groupName?: string;
        moduleId?: string;
    };
    id: string;
    lastMessage: {
        attachment?: any;
        conversationId?: string;
        createdAt?: number;
        id?: string;
        location?: any;
        origin?: string;
        readBy?: string[];
        senderId?: string;
        text?: string;
    };
    participants: IChatParticipant[];
    photoUrl: string;
    subject: string;
    topicId: string;
    welcomeMessages: string;
    type: ChatConversationType;
}

export interface IChatParticipant {
    access: 'ReadWrite' | 'Read';
    fullName: string;
    email: string;
    imageUrl: string;
    notify: boolean;
    userId: string;
    isOnline: boolean;
}

export interface IFullConversationData extends IChatPanelConversationData, IChatDrawerConversationData {
    lastMessageTimestamp: number;
    drawerTimestamp: number;
    openedFrom: ConversationOpenedFromContactCard;
    participantId?: string;
    groupName: string;
    drawerOpenedTimestamp: string;
    email: string;
    type: ChatConversationType;
    isOnline?: boolean;
    userId?: string;
}

export interface ChatAnalytics {
    conversation_type: ChatConversationType;
    opened_from: ConversationOpenedFrom;
    conversation_id: string;
    conversation_name: string;
    module_name?: string;
    event_source?: 'web' | 'android' | 'ios';
}

export interface IUserOnlineInfo {
    id: string;
    isOnline: boolean;
}

const sortByPoperty: (property: string) => (a: IFullConversationData, b: IFullConversationData) => number = (property) => {
    return (a, b) => {
        const valueA = Number.parseInt(a[property], 10) || 0;
        const valueB = Number.parseInt(b[property], 10) || 0;

        return valueA < valueB ? 1 : valueB < valueA ? -1 : 0;
    };
};

@Injectable()
export class TalkService {
    private _suspendSnackbars = false;

    private _attemptToInit = false;

    enabled = new Promise<boolean>(async (resolutionFunc, rejectionFunc) => {
        await this._action$.pipe(ofActionDispatched(FetchFeaturesSuccess), take(1)).toPromise();

        const feature = FeatureState.getEntity(this._store.selectSnapshot(FeatureState), 'chat');
        const hidden = !feature || feature.status === 'inactive';

        resolutionFunc(!hidden);

        this._fuseConfigService.updateDefaultConfig({
            layout: {
                sidepanel: {
                    hidden,
                },
            },
        });

        this._fuseConfigService.setConfig({
            layout: {
                sidepanel: {
                    hidden,
                },
            },
        });
    });

    private _currentUser: Talk.User;
    get currentUserId(): string {
        return this._currentUser.id;
    }
    get currentUser(): Talk.User {
        return this._currentUser;
    }
    set currentUser(value: Talk.User) {
        if (value && !this.currentUser) {
            this.fetchMoreConversations();
        }

        if (!value) {
            this._currentUser = value;
            this.currentSession = null;
            return;
        }

        if (value.locale !== this.selectedLanguageId) {
            this._currentUser = new Talk.User({ ...value, role: 'Default', locale: this.selectedLanguageId });
        } else {
            this._currentUser = value;
        }

        this.currentSession = new Talk.Session({
            appId: this._tenantDataService.tenantConfig.chat.api_key,
            me: this._currentUser,
        });
    }

    private _currentSession: Talk.Session;
    set currentSession(value: Talk.Session) {
        if (this._currentSession) {
            this._currentSession.destroy();
        }

        this._currentSession = value;

        if (!this._currentSession) {
            return;
        }

        this.notifications();

        this._currentSession.on('message', (x) => this.messageEventHandler(x));
        this._currentSession.unreads.on('change', (x) =>
            setTimeout(() => {
                this.unreadsChangeEventHandler(x);
            }, 3000)
        );
    }

    private _currentSession$ = new Subject<Talk.Session>();

    private _conversations = new Map<string, IFullConversationData>();
    private _lastOffsetTs: number;

    private _conversation$ = new BehaviorSubject<IFullConversationData[]>([]);
    private _onlineInfo$ = new BehaviorSubject<IUserOnlineInfo[]>([]);

    panelConversationDetail$: Observable<IFullConversationData[]> = this._conversation$.asObservable();
    // .pipe(map(conversations => conversations.slice().sort(sortByPoperty('lastMessageTimestamp'))));
    drawerConversationDetail$: Observable<IFullConversationData[]> = this._conversation$.asObservable().pipe(
        map((conversations) => conversations.filter((conversation) => conversation.expanded !== null).sort(sortByPoperty('drawerOpenedTimestamp'))),
        tap((conversations) => {
            this.numberOfDrawerConversations = conversations.length;
            const conversationIds = conversations.map(({ id }) => id);

            // Chats are sorted by drawerOpenedTimestamp, We're taking first opened conversation and close it if three chat's are already opened
            if (conversationIds.length > 3) {
                const lastOpenedConversationId = conversations.length ? conversations[3].id : null;
                this.numberOfDrawerConversations = conversations.length;

                setTimeout(() => {
                    this.closeConversation(lastOpenedConversationId);
                });
            }

            localStorage.setItem('drawerConversations', JSON.stringify(conversationIds));
        })
    );
    userOnlineInfoDetails$: Observable<IUserOnlineInfo[]> = this._onlineInfo$.asObservable();
    drawerConversations: string[] = JSON.parse(localStorage.getItem('drawerConversations')) || [];
    numberOfDrawerConversations = this.drawerConversations.length;

    get conversations(): IFullConversationData[] {
        return Array.from(this._conversations.values());
    }

    selectedLanguage: ILanguage = this.languageService.getDefaultLanguage();

    private get selectedLanguageId(): string {
        if (!this.selectedLanguage) {
            return null;
        }

        return this.selectedLanguage.id === 'en-GB' ? 'en-US' : this.selectedLanguage.id;
    }

    private _analytic$ = new Subject<ChatAnalytics>();
    analytic$ = this._analytic$.asObservable();

    private _fetchLimit = initialFetchLimit;
    nextPageNumber = new Map<string, number>();
    isThereMorePages = new Map<string, boolean>();
    private _isFetchingMoreConversations = false;
    get isFetchingMoreConversations(): boolean {
        return this._isFetchingMoreConversations;
    }

    usersIdsList: string[] = [];
    uniqueUsersIdsList: string[] = [];
    interval: NodeJS.Timer;

    constructor(
        private _http: HttpClient,
        private _store: Store,
        private _action$: Actions,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private _matSnackBar: MatSnackBar,
        private _fuseConfigService: FuseConfigService,
        private _tenantDataService: TenantDataService,
        private _userApiService: UserApiService,
        private _featureService: FeatureService,
        @Inject(MAXBRAIN_LANGUAGE_SERVICE) public languageService: ILanguageService
    ) {
        this._translateService.onLangChange.subscribe(async ({ lang }) => {
            this.selectedLanguage = this.languageService.getLanguageById(lang);

            this.currentUser = this.currentUser;
        });

        this._action$.pipe(ofActionDispatched(UpdateUserSuccess)).subscribe(({ payload }: UpdateUserSuccess) => {
            const conversationId = Talk.oneOnOneId(this.currentUserId, `${this._tenantDataService.subdomain}-${payload.id}`);

            if (this._conversations.has(conversationId)) {
                const conversationData: IFullConversationData = { ...this.getFullConversationData(payload, this._conversations.get(conversationId)) };
                this._conversations.set(conversationId, conversationData);
                this._conversation$.next(this.conversations);
            }
        });

        this._action$.pipe(ofActionDispatched(UpdateGroupSuccess, UpdateModuleGroupSuccess)).subscribe(async ({ payload }: UpdateGroupSuccess | UpdateModuleGroupSuccess) => {
            const conversation = await this.getConversationByGroup(payload, ChatConversationType.GeneralModuleGroup);

            if (!conversation) {
                return;
            }

            const conversationId = conversation['id'];

            if (this._conversations.has(conversationId)) {
                const conversationData: IFullConversationData = {
                    ...this.getFullConversationData(null, { ...this._conversations.get(conversationId), photoUrl: payload.image?.src, title: payload.name }),
                };
                this._conversations.set(conversationId, conversationData);
                this._conversation$.next(this.conversations);
            }
        });

        this._action$.pipe(ofActionDispatched(LeaveGroupSuccess, LeaveModuleGroupSuccess)).subscribe(({ payload }: LeaveGroupSuccess | LeaveModuleGroupSuccess) => {
            this._conversations.delete(this.getConversationIdByGroupId(payload));
            this._conversation$.next(this.conversations);
        });
    }

    sendAnalytics(analytics: ChatAnalytics): void {
        this._analytic$.next(analytics);
    }

    getFullConversationDataById(id: string): IFullConversationData {
        return this._conversations.get(id);
    }

    updateModuleGroupImage(conversationId: string, imageUrl: string) {
        if (this._conversations.has(conversationId)) {
            this.conversations.find((conversation) => conversation.id === conversationId).photoUrl = imageUrl;
            this._conversation$.next(this.conversations);
        }
    }

    updateEmailNotification(value: boolean): void {
        const currentUser = this.currentUser;

        if (currentUser) {
            this.currentUser = new Talk.User({ ...currentUser, email: value ? currentUser.custom.email : null });
        }
    }

    getNumberOfConversationsToClose(windowWidth: number): number {
        const numberOfPossibleDrawerUsers = Math.floor((windowWidth - 120) / 320);

        return this.numberOfDrawerConversations - numberOfPossibleDrawerUsers;
    }

    private async notifications(): Promise<void> {
        try {
            await this._currentSession.setDesktopNotificationEnabled(true, { alertOnFailure: false });
        } catch (e) {
            console.warn(e);
        }
    }

    private messageEventHandler(data): void {
        if (data.isByMe) {
            if (!this._conversations.has(data.conversation.id)) {
                let title = data.conversation.custom.groupName || data.conversation.custom.moduleName;

                if (!title) {
                    const conversationData = this._conversations.get(data.conversation.id);
                    title = conversationData.initials;
                }

                this._registerConversationByData(
                    this.getFullConversationData(null, {
                        title,
                        lastMessageTimestamp: data.timestamp,
                        ...data.conversation,
                    }),
                    true
                );
            } else {
                const conversationData = this._conversations.get(data.conversation.id);
                conversationData.lastMessageTimestamp = data.timestamp;
                this._conversation$.next(this.conversations);
            }
        }
    }

    private async unreadsChangeEventHandler(conversationIds): Promise<void> {
        conversationIds.forEach(async ({ lastMessage }) => {
            if (!lastMessage.isByMe) {
                let conversationData = this._conversations.get(lastMessage.conversation.id);

                if (!conversationData) {
                    if (lastMessage.conversation.subject === lastMessage.conversation.custom.groupName) {
                        this.registerConversation(lastMessage.conversation);
                    } else {
                        let onlineStateResponse = this._featureService.checkFeatureStatus(FeatureSwitchName.UserOnlineState)
                            ? await this._userApiService.getUserOnlineState([lastMessage.sender.custom.email])
                            : null;

                        await this.addUserById({
                            id: Talk.oneOnOneId(this.currentUserId, lastMessage.senderId),
                            participantId: lastMessage.senderId,
                            lastMessageTimestamp: lastMessage.timestamp,
                            lastMessageText: lastMessage.text,
                            email: lastMessage.sender.custom.email,
                            isOnline: onlineStateResponse?.onlineState[0].isOnline,
                            title: lastMessage.conversation.custom.moduleName,
                        });
                    }

                    conversationData = this._conversations.get(lastMessage.conversation.id);
                }

                conversationData.lastMessageTimestamp = lastMessage.timestamp;
                conversationData.lastMessageText = lastMessage.text;

                if (conversationData.lastMessageRead !== lastMessage.read) {
                    const isExpanded = conversationData.expanded === true;
                    const lastMessageRead = isExpanded || lastMessage.read;

                    if (!lastMessageRead) {
                        conversationData.expanded = false;

                        if (this._suspendSnackbars) {
                            this._matSnackBar
                                .open(this._translateService.instant('Talk.SNACKBAR.MESSAGE', lastMessage.sender), this._translateService.instant('Talk.SNACKBAR.BUTTON'), {
                                    verticalPosition: 'top',
                                    duration: 5000,
                                })
                                .onAction()
                                .subscribe(() => {
                                    this.updateConversation(conversationData.id, { expanded: true, openedFrom: 'snackbar' });
                                });
                        }
                    }

                    conversationData.lastMessageRead = lastMessageRead;

                    this._conversation$.next(this.conversations);
                }
            }
        });
    }

    /**
     * Fold the temporarily unfolded sidebar back
     */
    foldSidebar(): void {
        this._fuseSidebarService.getSidebar('chatPanel').foldTemporarily();
    }

    /**
     * Open the temporarily folded sidebar back
     */
    openSidebar(): void {
        this._fuseSidebarService.getSidebar('chatPanel').unfoldTemporarily();
    }

    /**
     * Fold the temporarily unfolded sidebar back
     */
    toggleSidebar(): void {
        this._fuseSidebarService.getSidebar('chatPanel').unfolded
            ? this._fuseSidebarService.getSidebar('chatPanel').foldTemporarily()
            : this._fuseSidebarService.getSidebar('chatPanel').unfoldTemporarily();
    }

    async getSession(): Promise<Talk.Session> {
        return this._currentSession || (await this.createCurrentSession());
    }

    async newTalkUser(options: Talk.UserOptions): Promise<Talk.User> {
        await Talk.ready;

        return new Talk.User({ ...options, role: 'Default' });
    }

    async createUser(applicationUser: User, expanded: boolean = null): Promise<Talk.User> {
        if (!applicationUser) {
            return null;
        }

        const id = `${this._tenantDataService.subdomain}-${applicationUser.id}`;
        const photoUrl = applicationUser.avatar.src !== '' ? applicationUser.avatar.src : null;
        const locale = this.selectedLanguageId;

        return await this.newTalkUser({
            id,
            name: applicationUser.fullName,
            photoUrl,
            email: applicationUser.emailNotification ? applicationUser.email : null,
            custom: {
                id: applicationUser.id,
                expanded: `${expanded}`,
                initials: applicationUser.initials,
                tenant: this._tenantDataService.subdomain,
                email: applicationUser.email,
            },
            locale,
        });
    }

    async getUserById(userId: string): Promise<User> {
        let user = UserState.getEntity(this._store.selectSnapshot(UserState), userId);

        if (!user) {
            const accessToken = await this._store
                .select(MaxBrainAuthState.getAccessToken)
                .pipe(
                    filter((x) => !!x),
                    take(1)
                )
                .toPromise();

            user = await this._http
                .get<IUserQuery>(`${environment.apiUrl}/users/${userId}`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                })
                .pipe(map((query) => new User(query)))
                .toPromise();
        }

        return user;
    }

    async createUserById(userId: string): Promise<Talk.User> {
        let user = UserState.getEntity(this._store.selectSnapshot(UserState), userId);

        if (!user) {
            try {
                user = await this.getUserById(userId);
            } catch (e) {
                user = null;
            }
        }

        return await this.createUser(user);
    }

    private async asyncForEach(array: any[], callback: (item: any, index: number, array: any[]) => Promise<void>): Promise<any> {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
    }

    private getFullConversationData(user: User, { id, expanded, searchableTerm, groupName, email, isOnline, ...partial }: Partial<IFullConversationData>): IFullConversationData {
        if (!id) {
            return null;
        }
        const title = partial.title || (user ? user.fullName : '');
        const photoUrl = partial.photoUrl || (user ? user.avatar.src : '');
        const initials = title
            .split(' ')
            .slice(0, 2)
            .map((word) => word.charAt(0).toUpperCase())
            .join('');

        return {
            ...partial,
            expanded: expanded === false ? false : expanded === true ? true : null,
            id,
            title,
            photoUrl,
            // color: MaxBrainTag.getRandomColorFromList(MaxBrainTag.colorsArray),
            initials,
            participant: user,
            searchableTerm,
            groupName,
            email,
            isOnline,
        } as IFullConversationData;
    }

    // tslint:disable-next-line: max-line-length
    private async addUserById({
        id,
        participantId,
        lastMessageTimestamp,
        lastMessageText,
        photoUrl,
        title,
        searchableTerm,
        groupName,
        isOnline,
        email,
        chatParticipants,
        userId,
        type,
    }: Partial<IFullConversationData>): Promise<void> {
        if (this._conversations.has(id)) {
            return;
        }

        const tempUser = !participantId ? null : await this.getUserById(participantId.split('-').slice(-1)[0]);

        if (participantId && !tempUser) {
            return;
        }

        const partialConversationData: Partial<IFullConversationData> = {
            id: id,
            lastMessageTimestamp,
            lastMessageText,
            photoUrl,
            title,
            searchableTerm,
            groupName,
            isOnline,
            email,
            chatParticipants,
            userId,
            type,
        };

        if (this.drawerConversations.indexOf(id) !== -1) {
            partialConversationData.expanded = false;
        }

        this._conversations.set(id, this.getFullConversationData(tempUser, partialConversationData));
    }

    async fetchMoreConversations({ searchTerm }: { searchTerm: string } = { searchTerm: '' }, limit: number = this._fetchLimit): Promise<{ isThereMorePages: boolean }> {
        if (this.isFetchingMoreConversations) {
            return null;
        }

        this._isFetchingMoreConversations = true;

        let params = new HttpParams().set('limit', limit.toString());

        if (searchTerm) {
            params = params.set('search', searchTerm);
        }

        if (this._lastOffsetTs) {
            params = params.set('offsetTs', this._lastOffsetTs.toString());
        }

        let isThereMorePages = this.isThereMorePages.get(searchTerm || '');

        if (isThereMorePages) {
            try {
                const accessToken = await this._store
                    .select(MaxBrainAuthState.getAccessToken)
                    .pipe(
                        filter((x) => !!x),
                        take(1)
                    )
                    .toPromise();

                if (!this.currentUser) {
                    await this.getSession();
                }

                let conversations: Partial<IFullConversationData>[] = await this._http
                    .get<{ data: IFullConversationQuery[]; offsetTs: number }>(`${environment.apiUrl}/v1/chat/users/conversations`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                        params,
                    })
                    .pipe(
                        tap(({ offsetTs }) => {
                            this._lastOffsetTs = offsetTs;
                            if (offsetTs === null) {
                                isThereMorePages = false;
                            }
                        }),
                        map(({ data }) => {
                            return data.map(({ id, subject, participants, lastMessage, photoUrl, custom, type }) => {
                                const isNotGroupConversation = !subject;
                                let otherTalkUser: {
                                    access: 'ReadWrite' | 'Read';
                                    fullName: string;
                                    email: string;
                                    imageUrl: string;
                                    notify: boolean;
                                    userId: string;
                                };

                                if (isNotGroupConversation) {
                                    const notMeUsers = participants.filter((participant) => participant['userId'] !== this.currentUserId);
                                    this.usersIdsList.push(...participants.map((participant) => participant.userId.split(`${this._tenantDataService.subdomain}-`)[1]));

                                    if (notMeUsers.length === 1) {
                                        otherTalkUser = notMeUsers[0];

                                        if (otherTalkUser) {
                                            subject = otherTalkUser['fullName'];

                                            if (!photoUrl) {
                                                photoUrl = otherTalkUser['imageUrl'];
                                            }
                                        }
                                    }
                                } else {
                                    photoUrl = '/assets/icons/ic_group_chat.svg';
                                }

                                if (!lastMessage) {
                                    lastMessage = {
                                        createdAt: 0,
                                        text: '',
                                        readBy: [''],
                                    };
                                }

                                return {
                                    id,
                                    lastMessageTimestamp: lastMessage.createdAt,
                                    lastMessageText: lastMessage.text,
                                    lastMessageRead: lastMessage.readBy.indexOf(this.currentUser.id) !== -1,
                                    photoUrl,
                                    title: subject,
                                    searchableTerm: custom.conversationName,
                                    groupName: custom.groupName,
                                    email: custom.groupName ? null : otherTalkUser.email,
                                    chatParticipants: participants,
                                    type,
                                    userId: custom.groupName
                                        ? null
                                        : participants.find((participant) => participant.email === otherTalkUser.email).userId.split(`${this._tenantDataService.subdomain}-`)[1],
                                };
                            });
                        })
                    )
                    .toPromise();

                let onlineStateResponse: UserOnlineState;

                this.uniqueUsersIdsList = [...new Set(this.usersIdsList)];

                onlineStateResponse =
                    this.uniqueUsersIdsList.length && this._featureService.checkFeatureStatus(FeatureSwitchName.UserOnlineState)
                        ? await this._userApiService.getUserOnlineState(this.uniqueUsersIdsList)
                        : null;

                if (onlineStateResponse) {
                    conversations = conversations.map((conversation) => {
                        onlineStateResponse.onlineState.forEach((onlineState) => {
                            if (!conversation.groupName && onlineState.id === conversation.userId) {
                                conversation = Object.assign({}, conversation, {
                                    isOnline: onlineState.isOnline,
                                });
                            }

                            conversation = Object.assign({}, conversation, {
                                chatParticipants: conversation.chatParticipants?.map((participant) => {
                                    if (onlineState.id === participant.userId.split(`${this._tenantDataService.subdomain}-`)[1]) {
                                        participant = Object.assign({}, participant, {
                                            isOnline: onlineState.isOnline,
                                        });
                                    }

                                    return participant;
                                }),
                            });
                        });

                        return conversation;
                    });
                }

                this._fetchLimit = 5;

                await this.asyncForEach(conversations, this.addUserById.bind(this));

                this._conversation$.next(this.conversations);

                if (this.uniqueUsersIdsList.length && this._featureService.checkFeatureStatus(FeatureSwitchName.UserOnlineState)) {
                    this.checkOnlineState();
                }

                if (!searchTerm) {
                    if (this.conversations.length < initialFetchLimit && this._lastOffsetTs !== null) {
                        this._isFetchingMoreConversations = false;
                        this.fetchMoreConversations({
                            searchTerm,
                        });
                    }
                }
            } catch (e) {
                console.error(e);
            }
        }

        this._isFetchingMoreConversations = false;

        return {
            isThereMorePages,
        };
    }

    private checkOnlineState(): void {
        clearInterval(this.interval);

        this.interval = setInterval(async () => {
            let onlineStateResponse: UserOnlineState;
            let conversations: IFullConversationData[];

            onlineStateResponse = await this._userApiService.getUserOnlineState(this.uniqueUsersIdsList);

            if (onlineStateResponse) {
                conversations = this.conversations.map((conversation) => {
                    onlineStateResponse.onlineState.forEach((onlineState) => {
                        if (!conversation.groupName && onlineState.id === conversation.userId) {
                            conversation = Object.assign({}, conversation, {
                                isOnline: onlineState.isOnline,
                            });
                        }

                        conversation = Object.assign({}, conversation, {
                            chatParticipants: conversation.chatParticipants?.map((participant) => {
                                if (onlineState.id === participant.userId.split(`${this._tenantDataService.subdomain}-`)[1]) {
                                    participant = Object.assign({}, participant, {
                                        isOnline: onlineState.isOnline,
                                    });
                                }

                                return participant;
                            }),
                        });
                    });

                    return conversation;
                });
            }

            await this.asyncForEach(conversations, this.addUserById.bind(this));

            this._onlineInfo$.next(
                conversations.map((conversation) => {
                    return {
                        id: conversation.id,
                        isOnline: conversation.isOnline,
                    };
                })
            );
        }, 30000);
    }

    private async createCurrentSession(): Promise<Talk.Session> {
        if (!this._attemptToInit) {
            this._attemptToInit = true;

            if (await this.enabled) {
                this.currentUser = await this.createUser(this._store.selectSnapshot(UserState.getMyInfo));

                this._currentSession$.next(this._currentSession);
            } else {
                return null;
            }
        }

        return this._currentSession || (await this._currentSession$.pipe(take(1)).toPromise());
    }

    async createConversationByUserId(userId: string): Promise<void> {
        const accessToken = await this._store
            .select(MaxBrainAuthState.getAccessToken)
            .pipe(
                filter((x) => !!x),
                take(1)
            )
            .toPromise();

        await this._http
            .put(`${environment.apiUrl}/chat/users/${userId}/conversations`, null, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
            .toPromise();
    }

    async getConversationIdByUserId(userId: string): Promise<string> {
        await Talk.ready;

        const conversationId = Talk.oneOnOneId(this.currentUserId, `${this._tenantDataService.subdomain}-${userId}`);

        return conversationId;
    }

    async getConversationByUserId(userId: string): Promise<Talk.ConversationBuilder> {
        const session = await this.getSession();
        const conversationId = await this.getConversationIdByUserId(userId);

        return session.getOrCreateConversation(conversationId);
    }

    getConversationIdByModuleId(moduleId: string): string {
        return `${this._tenantDataService.subdomain}-ct-${moduleId}`;
    }

    async getConversationByModule(currentModule: Module, conversation_type?: ChatConversationType, opened_from?: ConversationOpenedFrom): Promise<Talk.ConversationBuilder> {
        const session = await this.getSession();
        const conversationId = this.getConversationIdByModuleId(currentModule.id);
        if (opened_from) {
            this.sendAnalytics({
                conversation_type,
                opened_from,
                conversation_id: conversationId,
                conversation_name: currentModule.name,
                module_name: currentModule.name,
            });
        }

        return session.getOrCreateConversation(conversationId);
    }

    getConversationIdByGroupId(groupId: string): string {
        return `${this._tenantDataService.subdomain}-${groupId}`;
    }

    async getConversationByGroup(
        currentGroup: ModuleGroup | Group,
        conversation_type: ChatConversationType,
        currentModule?: Module,
        opened_from?: ConversationOpenedFrom
    ): Promise<Talk.ConversationBuilder> {
        const session = await this.getSession();
        const conversationId = this.getConversationIdByGroupId(currentGroup.id);
        if (opened_from) {
            this.sendAnalytics({ conversation_type, opened_from, conversation_id: conversationId, conversation_name: currentGroup.name, module_name: currentModule?.name || null });
        }

        return session.getOrCreateConversation(conversationId);
    }

    async startConversation(conversationData: IFullConversationData): Promise<void> {
        if (!conversationData) {
            return;
        }

        let n = 0;

        if (conversationData.expanded) {
            conversationData.drawerTimestamp = new Date().getTime();
            n = this.getNumberOfConversationsToClose(window.innerWidth) + 2; // add one for the uploads drawers and one for the user being added

            if (isConversationOpenedFrom(conversationData.openedFrom)) {
                this.sendAnalytics({
                    conversation_type: ChatConversationType.Dm,
                    opened_from: conversationData.openedFrom,
                    conversation_id: conversationData.id,
                    conversation_name: '-',
                });
                delete conversationData.openedFrom;
            }
        }

        let onlineStateResponse = this._featureService.checkFeatureStatus(FeatureSwitchName.UserOnlineState)
            ? await this._userApiService.getUserOnlineState([conversationData.participant.email])
            : null;

        if (onlineStateResponse) {
            conversationData = Object.assign({}, conversationData, {
                isOnline: onlineStateResponse.onlineState[0].isOnline,
            });
        }

        this._conversations.set(conversationData.id, conversationData);

        if (n) {
            this.closeOldestConversations(n);
        } else {
            this._conversation$.next(this.conversations);
        }
    }

    async startConversationByUser(user: User, openedFrom: ConversationOpenedFrom, expanded = true, shouldExpandIfExisting = true): Promise<void> {
        if (!user) {
            return;
        }

        await this.createConversationByUserId(user.id);

        const conversationId = await this.getConversationIdByUserId(user.id);

        if (this._conversations.has(conversationId)) {
            const conversationData = this.getFullConversationData(user, { ...this._conversations.get(conversationId), id: conversationId, openedFrom });
            this._updateConversation(conversationData, shouldExpandIfExisting);
            return;
        }

        await this.startConversation(this.getFullConversationData(user, { id: conversationId, expanded, openedFrom }));
    }

    private _updateConversation(conversationData, expanded: boolean): void {
        if (!conversationData) {
            return;
        }

        let n = 0;

        if (conversationData.expanded === null) {
            conversationData.drawerTimestamp = new Date().getTime();
            n = this.getNumberOfConversationsToClose(window.innerWidth) + 2; // add one for the uploads drawers and one for the user being added
        }

        if (expanded) {
            conversationData.lastMessageRead = true;
            conversationData.drawerOpenedTimestamp = conversationData.openedFrom === 'existing_collapsed' ? conversationData.drawerOpenedTimestamp : new Date().getTime();

            if (isConversationOpenedFrom(conversationData.openedFrom)) {
                this.sendAnalytics({
                    conversation_type: conversationData.type,
                    opened_from: conversationData.openedFrom,
                    conversation_id: conversationData.id,
                    conversation_name: conversationData.groupName || '-',
                });
                delete conversationData.openedFrom;
            }
        }

        conversationData.expanded = expanded;

        this._conversations.set(conversationData.id, conversationData);

        if (n) {
            this.closeOldestConversations(n);
        } else {
            this._conversation$.next(this.conversations);
        }

        this.foldSidebar();
    }

    updateConversation(id: string, { expanded, openedFrom }: IUpdateConversationData): void {
        const conversationData = this._conversations.get(id);

        conversationData.expanded = expanded;
        conversationData.openedFrom = openedFrom;

        this._updateConversation(conversationData, expanded);
    }

    private _registerConversationByData(conversationData: IFullConversationData, force: boolean = false): void {
        if (force || !this._conversations.has(conversationData.id)) {
            this._conversations.set(conversationData.id, { ...this._conversations.get(conversationData.id), ...conversationData });
            this._conversation$.next(this.conversations);
        }
    }

    registerConversation(conversation: Talk.ConversationBuilder, force: boolean = false): void {
        const conversationData = this.getFullConversationData(null, { title: conversation.custom.groupName || conversation.custom.moduleName, ...conversation });

        this._registerConversationByData(conversationData, force);
    }

    collapseAll(): void {
        this._conversations.forEach((value, key) => {
            const conversationData = this._conversations.get(key);

            if (conversationData.expanded) {
                this._updateConversation(conversationData, false);
            }
        });
    }

    closeConversation(id: string): void {
        const conversationData = this._conversations.get(id);

        if (!conversationData) {
            return;
        }

        conversationData.expanded = null;

        this._conversation$.next(this.conversations);
    }

    closeOldestConversations(n: number): void {
        if (!n) {
            return;
        }

        const drawerUsers = this.conversations.filter((conversationData) => conversationData.expanded !== null).sort(sortByPoperty('drawerTimestamp'));

        drawerUsers.slice(Math.max(drawerUsers.length - n, 0)).forEach((conversationData) => {
            conversationData.expanded = null;
        });

        this._conversation$.next(this.conversations);
    }

    closeAllConversations(): void {
        this._conversations.forEach((conversationData) => {
            conversationData.expanded = null;
        });

        this._conversation$.next(this.conversations);
    }

    suspendSnackbars(): void {
        this._suspendSnackbars = true;
        this._matSnackBar.dismiss();
    }

    unsuspendSnackbars(): void {
        this._suspendSnackbars = false;
    }
}

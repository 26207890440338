import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { MaxBrainUtils } from 'app/projects/core/src/lib/utils';
import { ClearExpiration } from '../actions/clear-expiration.action';
import { FetchAccessTokenFromRefreshTokenFailureAction } from '../actions/fetch-access-token-from-refresh-token.failure.action';
import { Logout } from '../actions/logout.action';
import { ResetRedirectUrl } from '../actions/reset-redirect-url.action';
import { ScheduleRenewal } from '../actions/schedule-renewal.action';
import { SetRedirectUrl } from '../actions/set-redirect-url.action';
import { SetReponseLoginApp } from '../actions/set-reponse-login-app.action';
import { SetSession } from '../actions/set-session.action';
import { LoginAppApiService } from '../services/login-app.api-service';
import { LoginAppService } from '../services/login-app.service';
import { MaxBrainAuthStateModel } from './auth.state-model';

@State<MaxBrainAuthStateModel>({
    name: 'auth',
    defaults: new MaxBrainAuthStateModel(),
})
@Injectable()
export class MaxBrainAuthState {
    private refreshSubscription: any;

    @Selector()
    static getRedirectUrl(state: MaxBrainAuthStateModel): string {
        return state.redirect;
    }

    @Selector()
    static isAuthenticated(state: MaxBrainAuthStateModel): boolean {
        return Date.now() < state.expiresAt && state.authenticated;
    }

    @Selector()
    static getAccessToken(state: MaxBrainAuthStateModel): string {
        return state.accessToken;
    }

    @Selector()
    static getRefreshToken(state: MaxBrainAuthStateModel): string {
        return state.refreshToken;
    }

    @Selector()
    static getExpiresAt(state: MaxBrainAuthStateModel): number {
        return state.expiresAt;
    }

    @Selector()
    static isCockpitUser(state: MaxBrainAuthStateModel): boolean {
        return state.isCockpitUser;
    }

    constructor(private _loginAppService: LoginAppService, private _loginAppApiService: LoginAppApiService) {}

    @Action(SetReponseLoginApp)
    setReponseLoginApp({ patchState }: StateContext<MaxBrainAuthStateModel>, { payload }: SetReponseLoginApp): void {
        patchState({
            authenticating: true,
            accessToken: payload.token,
            refreshToken: payload.refresh_token,
            expiresAt: Number.parseInt(payload.token_expiration, 10) || null,
        });
    }

    @Action(SetSession)
    setSession({ getState, patchState, dispatch }: StateContext<MaxBrainAuthStateModel>, { payload }: SetSession): void {
        const expiresAt = payload.authResult.exipresAt || payload.authResult.expiresIn * 1000 + Date.now();
        const tokenPayload = MaxBrainUtils.decodeToken(payload.authResult.accessToken);

        if (!tokenPayload) {
            throw new Error('Unable to extract token payload. Something is wrong with the access token: ' + payload.authResult.accessToken);
        }
        patchState({
            expiresAt,
            userProfile: payload.profile,
            accessToken: payload.authResult.accessToken,
            authenticated: true,
            authenticating: false,
            isCockpitUser: !!tokenPayload['https://maxbrain.io/is_cockpit_user'],
        });

        const state = getState();

        localStorage.setItem('userProfile', JSON.stringify(state.userProfile));
        localStorage.setItem('accessToken', state.accessToken);
        localStorage.setItem('isCockpitUser', state.isCockpitUser.toString());

        dispatch(new ScheduleRenewal());
    }

    @Action(FetchAccessTokenFromRefreshTokenFailureAction)
    FetchAccessTokenFromRefreshTokenFailureAction(
        { patchState, dispatch }: StateContext<MaxBrainAuthStateModel>,
        { payload }: FetchAccessTokenFromRefreshTokenFailureAction
    ): void {
        // we reset the refresh access and expire since this data are not available anymore

        localStorage.removeItem('expiresAt');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');

        patchState({
            expiresAt: null,
            accessToken: null,
            refreshToken: null,
            authenticated: false,
            authenticating: false,
        });

        dispatch(new Logout());
    }

    @Action(SetRedirectUrl)
    setRedirect({ patchState }: StateContext<MaxBrainAuthStateModel>, { payload }: SetRedirectUrl): void {
        localStorage.setItem('redirect', payload);

        patchState({
            redirect: payload,
        });
    }

    @Action(ResetRedirectUrl)
    resetRedirect({ patchState }: StateContext<MaxBrainAuthStateModel>): void {
        localStorage.removeItem('redirect');

        patchState({
            redirect: null,
        });
    }

    @Action(ClearExpiration)
    clearExpiration({ patchState }: StateContext<MaxBrainAuthStateModel>): void {
        localStorage.removeItem('expiresAt');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');

        patchState({
            expiresAt: null,
            accessToken: null,
            refreshToken: null,
            authenticated: false,
        });
    }

    @Action(Logout)
    logout({ dispatch }: StateContext<MaxBrainAuthStateModel>): void {
        const refreshToken = localStorage.getItem('refreshToken');

        if (refreshToken) {
            this._loginAppApiService.logout(localStorage.getItem('refreshToken')).then(() => {
                this._loginAppService.redirectToLoginPage();
                dispatch(new ClearExpiration());
            });
        } else {
            this._loginAppService.redirectToLoginPage();
            dispatch(new ClearExpiration());
        }
    }

    @Action(ScheduleRenewal)
    scheduleRenewal({ getState }: StateContext<MaxBrainAuthStateModel>): void {
        const state = getState();

        if (!MaxBrainAuthState.isAuthenticated(state)) {
            return;
        }
    }
}
